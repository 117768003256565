import * as dom from '../../util/easy-dom';
import * as events from '../../util/event-bus';
import {queryStringObject} from '../../util/cmpt-common-features';

export default function (elem, attrs, ctx, i18n) {
	var { access, productId } = ctx;

	const qsData = queryStringObject();
	if (qsData.productId != null) { productId = qsData.productId; }
	const productIdUpper = productId.toUpperCase();

	/////
	// get handle on elements we need
	//
	const domPickOrg = elem.parent().find('.sk-pick-org')[0];
	const domAppList = elem.find('.sk--org-app-list')[0];
	const domOrgFilter = elem.find('.sk--filtered-org-name')[0];

	/////
	// listen and react to events
	//
	events.on('_pick-org.org-id-selected', function (orgId) {
		if (orgId == null) { return; }

		const selectedOrgPA = ctx.access.find(({OrganizationId} = {}) => OrganizationId != null && OrganizationId.toString() === orgId.toString());

		if (selectedOrgPA == null) { return; }

		domOrgFilter._.innerHTML = selectedOrgPA.OrganizationName;
		domPickOrg.css('display', 'none');

		const weHaveAListToShow = setupAppList(orgId);

		if (weHaveAListToShow) {
			dom.rAF(function () {
				elem.css('display', 'block');
				dom.rAF(() => elem.css('opacity', 1));
			});
		}
	});

	/////
	// helper functions
	//
	function deDupAppAccess(accessRecords) {
		return accessRecords.filter(function (v, i, a) {
			const idx = a.findIndex(av => av.ProductId.toUpperCase() === v.ProductId.toUpperCase());
			return idx === i;
		});
	}

	function setupAppList(orgId) {
		var appListAccessRecords = access.filter(function (a) {
			return a.OrganizationId.toString() === orgId.toString() &&
				a.ProductId.toUpperCase() === productIdUpper;
		});

		if (appListAccessRecords.length === 0) {
			appListAccessRecords = access.filter(
				a => a.OrganizationId.toString() === orgId.toString()
			);
		}

		// de-duplicate the records to just unique orgs
		appListAccessRecords = deDupAppAccess(appListAccessRecords);

		// if we are left with just 1, pick it
		if (appListAccessRecords.length === 1) {
			dom.rAF(function () {
				events.emit('_pick-app.product-id-selected', appListAccessRecords[0].ProductId);
			});
			return false; // don't show this screen
		}

		appListAccessRecords.sort(function (a, b) {
			const aName = a.ProductName.toLowerCase();
			const bName = b.ProductName.toLowerCase();
			if (aName > bName) { return 1; }
			if (aName < bName) { return -1; }
			return 0;
		});

		domAppList.children(appListAccessRecords.map(function (a) {
			const item = dom.parse(`
				<li>
					<a href="#" data-app-id="${a.ProductId}">
						<span>${a.ProductName}</span>
					</a>
				</li>
			`);

			item.on('click', function(evt) {
				evt.preventDefault();
				events.emit('_pick-app.product-id-selected', a.ProductId);
			});

			return item;
		}));

		return true; // show the screen
	}
};
