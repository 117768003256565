const matcherMap = {};

export const pathMatchesRoute = function (path, route, matcher, baseUrl = '') {
	if (path == null) { return false; }

	const {host, protocol} = window.location;
	const fqdn = `${protocol}//${host}`;
	const fqdnPath = `${fqdn}${path}`;

	// if a matcher value is provided, check it first
	if (typeof matcher === 'string' && matcher.length) {
		// if we've never seen this pattern before, cache it
		if (matcherMap[matcher] == null) {
			matcherMap[matcher] = new RegExp(matcher, 'i');
		}
		// check the pattern agains the path AND FQDN locations
		return matcherMap[matcher].test(path)
			|| matcherMap[matcher].test(fqdnPath);
	} else if (matcher instanceof Array) {
		// multiple matchers can be provided
		// cache the generated RegExp list for future execution
		if (matcher._re == null) {
			matcher._re = matcher.map(rule => new RegExp(rule, 'i'));
		}
		// check the pattern agains the path AND FQDN locations
		return matcher._re.reduce((state, re) => state
			|| re.test(path)
			|| re.test(fqdnPath)
		, false);
	} else if (typeof route === 'string') {
		// if the route is an absolute link to the current domain
		// convert to a relative link for path comparison
		if (route.startsWith(fqdn)) { route = route.slice(fqdn.length); }
		route = baseUrl + route;
		return path.startsWith(route);
	}

	// all attempts to match this route have failed
	return false;
};
