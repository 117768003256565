import * as dom from '../../util/easy-dom'; // eslint-disable-line no-unused-vars

export default function(elem, attrs, ctx, i18n) {
	// position: bottom, top, left, right
	const position = attrs.position || 'bottom';
	const flipSide = {left: 'right', right: 'left', top: 'bottom', bottom: 'top'};
	const distance = defaultToPx(attrs.distance) || '-3px';
	let hoverTimeout = null;

	shiftTheArrow(attrs.arrowShift);
	offsetTheTip(attrs.tipShift);

	elem.addClass(`sk-tip-pos-${position}`);
	elem.css({
		[`margin-${flipSide[position]}`]: distance
	});

	elem.parent().addClass('sk-tooltip-container');

	// add a 1 second delay before the tooltip shows up
	elem.parent().on('mouseenter', (event) => {
		hoverTimeout = setTimeout(() => {
			elem.parent().addClass('sk--hover');
		}, 1000);
	});
	elem.parent().on('mouseleave', (event) => {
		clearTimeout(hoverTimeout);
		hoverTimeout = null;
		elem.parent().removeClass('sk--hover');
	});

	/////
	// helper functions
	//
	function shiftTheArrow(arrowShift) {
		if (arrowShift == null) { return; }
		const arrowEl = elem.find('.sk-tip-chevron')[0];
		arrowShift = defaultToPx(arrowShift);
		const direction = (['left', 'right'].includes(position) ? 'Y' : 'X');

		arrowEl.css({
			transform: `translate${direction}(${arrowShift}) rotate(45deg)`
		});
	}

	function offsetTheTip(tipShift) {
		const bounds = elem.parent()._.getBoundingClientRect();
		const direction = (['left', 'right'].includes(position) ? 'Y' : 'X');
		const defaultShift = (direction === 'Y' ? '50%' : '5px');
		const leftOrRight = (window.innerWidth - bounds.right < bounds.left ? 'right' : 'left');
		const propShift = (direction === 'Y' ? 'top' : leftOrRight);

		tipShift = defaultToPx(tipShift) || defaultShift;
		elem.css({
			[propShift]: tipShift
		});
		if (direction === 'X') {
			elem.find('.sk-tip-chevron')[0].css({
				[propShift]: '18px'
			});
		}
	}

	function defaultToPx(val) {
		if (typeof val !== 'string' && typeof val !== 'number') { return; }
		if (parseInt(val, 10).toString().length === val.toString().length) {
			val += 'px';
		}
		return val;
	}
};
