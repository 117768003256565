import * as store from '../../util/store';
import * as events from '../../util/event-bus';

/**
 * Launches a modal window with a countdown until sessions times out.
 * @class Sidekick.SessionTimeout
 * @memberOf Sidekick
 * @param {EasyDom} elem - Container {@link EasyDom} element
 * @param {Object} attrs
 * @param {Object} ctx
 * @param {Function} i18n - translation function
 * @emits session.logout - Thrown when the logout button is clicked
 * @emits session.extend - Thrown when the stay signed in button is clicked
 * @listens session-timeout.clear - Clears the countdown and hides the modal
 * @example
 * timer: seconds to countdown
 * {
	'timer': 180
}
 */


class Countdown {
	constructor() {
    this.duration = 0;
    this.elapsed = 0;
    this.isActive = false;
    this.lastFrameTime = Date.now();
    this.onTick = () => {};
    this.onCompleted = () => {};

    this.tick();
  }

  getTimeLeft() {
    const t = this.duration - this.elapsed;

    return Math.max(0, t);
  }

  pause() {
	this.isActive = false;
    return this;
  }

  reset() {
	this.isActive = false;
    this.elapsed = 0;
  }

  setDuration(seconds) {
    this.lastFrameTime = Date.now();
    this.duration = seconds;

    return this;
  }

  start() {
	this.isActive = true;

    return this;
  }

  tick() {
	const currentFrameTime = Date.now();
    const deltaTime = currentFrameTime - this.lastFrameTime;
    this.lastFrameTime = currentFrameTime;

    if (this.isActive) {
      this.elapsed += deltaTime / 1000;
      this.onTick(this.getTimeLeft());

      if(this.getTimeLeft() <= 0) {
        this.pause();
        this.onCompleted();
      }
    }

    window.requestAnimationFrame(this.tick.bind(this));
  }
}

export default function(elem, attrs, ctx, i18n) {

	let timerInterval;

	/////
	// find dom elements that we care about
	//
	const domLogout = elem.find('.sk--logout')[0];
	const domExtendBtn = elem.find('.sk--extend')[0];
	const domTimer = elem.find('.sk--timer')[0];
	const domSignedOut = elem.find('.sk--signed-out')[0];
	const domSignInLink = elem.find('.sk--sign-in-link')[0];

	/////
	// handle initial state and data changes
	//
	store.onValue('session-timeout', load);
	events.on('session-timeout.clear', clear);

	/////
	// listen for events and take action
	//
	domSignInLink.on('click', evt => {
		evt.stopPropagation();
		evt.preventDefault();

		// reload the page on the current url so we come back to what we were doing.
		window.location.reload();
	});

	domLogout.on('click', evt => {
		evt.stopPropagation();
		evt.preventDefault();

		clearInterval(timerInterval);

		events.emit('session.logout');
		events.emit('sk-menu-close', elem);
	});

	domExtendBtn.on('click', evt => {
		evt.stopPropagation();
		evt.preventDefault();

		clear();

		events.emit('session.extend');
		events.emit('sk-menu-close', elem);
	});

	/////
	// helper functions
	//

	// timer defaults to 3 minutes
	let countdown;
	function load({timer = 180} = {}) {
		countdown = new Countdown().setDuration(timer);

		// show the main component (which starts out hidden)
		elem.addClass('show');

		if (timer <= 0) {
			events.emit('session-timeout.timer-end');
			showSignedOutScreen();
			return; // they are already signed out... we be done
		}

		// fill the countdown timer and show it

		countdown.onTick = (time) => {
			domTimer._.innerHTML = format(Math.round(time));
		};
		countdown.start();
	}

	countdown.onCompleted = () => {
		events.emit('session-timeout.timer-end');
		showSignedOutScreen();
	};

	function showSignedOutScreen() {
		domSignedOut.addClass('show');
		events.emit('sk-menu-close', elem);
	}

	function format(val) {
		var seconds = val;
		var minutes = Math.floor(seconds / 60);
		seconds -= minutes * 60;

		if (seconds < 10) {
			seconds = '0' + seconds;
		}
		return minutes + ':' + seconds;
	}

	function clear() {
		countdown.reset();
		domTimer._.innerHTML = Math.ceil(countdown.getTimeLeft());
		domSignedOut.removeClass('show');
		elem.removeClass('show');
	}
};
