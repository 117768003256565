// es6 polyfills
import './util/polyfill';
// register ambient and the states used by the sidekick
import './ambient/ambient';
import './ambient/ambient-height';
import './ambient/ambient-width';
// init ambient states
import './ambient/ambient-config-states';

import init from './init';
import cycle from './cmpt/index';
import * as store from './util/store';
import * as events from './util/event-bus';
import * as dom from './util/easy-dom';

const _win = (0, eval)('this'); // eslint-disable-line no-eval

/////
// register the global sidekick object
//
if (_win.sidekick !== undefined) {
	console && console.error && console.error('TOO MANY SIDEKICKS => the sidekick was loaded twice on this page.'); // eslint-disable-line
}
_win.sidekick = _win.sidekick || {
	init, cycle, dom,
	store: store.publicApi,
	events: events.publicApi
};
