import {doCall} from './ajax';
import * as store from './store';
import * as events from './event-bus';
import * as dom from './easy-dom';
import * as env from './env';
import {deprecatedAttr} from './deprecated';

const idmDataKeys = ['productId', 'access', 'user', 'session', 'roles'];

// shorthand for these long attributes that are used often
const _oid = 'OrganizationId';
const _pid = 'ProductId';

export const processIdmData = function (idmData, {users, targetOrgId} = {}) {
	// ensure our orgId is always a string so that we can correctly do comparisons
	if (typeof targetOrgId === 'number') {
		targetOrgId = targetOrgId.toString();
	}

	const dataKeys = Object.keys(idmData);
	const missingIdmMeta = users == null;
	const missingIdmData = idmDataKeys.reduce(function (prev, key) {
		return prev || !dataKeys.includes(key);
	}, false);

	// if we are waiting on data, don't process yet
	if (missingIdmData || missingIdmMeta) { return; }

	const flInternalProducts = store.get('fl-internal-product-ids') || [];
	const isInternalProduct = flInternalProducts.includes(idmData.productId.toLowerCase());
	const productIdUpper = idmData.productId.toUpperCase();
	const filteredAccessRecords = idmData.access
		.filter(filterDupProductAndOrgRecords)
		.filter(a => a[_pid].toUpperCase() === productIdUpper)
		.filter(a => !flInternalProducts.includes(a[_pid].toLowerCase()));

	const state = idmData.session && idmData.session.State || {};

	// ensure our orgId is always a string so that we can correctly do comparisons
	if (typeof state.currentOrg === 'number') {
		state.currentOrg = state.currentOrg.toString();
	}

	const currentOrg = (targetOrgId || state.currentOrg || null);
	const isIdmAdmin = idmData.roles.map(r => r.toLowerCase()).includes('idmadministrator');

	// if the user is NOT an IDM Administrator and they are trying to access an internal screen
	// redirect them to the /select screen so they can pick something they are allowed to see
	if (!isIdmAdmin && isInternalProduct) {
		const {idGatewayUrl} = store.get('idm');
		const envName = env.environmentFromIdmGatewayUrl(idGatewayUrl);
		window.location.href = `${env[envName].ssBaseUrl}/select/`;
	}

	// if the user is an IdmAdministrator and they are accessing an org they don't
	// have access to, or an internal product, create a fake access record for them
	if (isIdmAdmin && productIdUpper && (currentOrg != null || isInternalProduct)) {
		const targetProductAccessRecord = (
			isInternalProduct
				? null
				: filteredAccessRecords.find(a => currentOrg === a[_oid])
		);

		if (targetProductAccessRecord == null) {

			const DefaultSignInUrl = idmData.product && idmData.product.DefaultSignInUrl || '';
			const OrgNavigationUrlTemplate = idmData.product && idmData.product.OrgNavigationUrlTemplate || '';
			const Url = DefaultSignInUrl;
			const ProductName = idmData.product && idmData.product.Name || '';
			const ProductLegacyName = idmData.product && idmData.product.LegacyName || null;

			const fakeProductAccess = {
				FlId: users.Id.toString(),
				Disabled: false,
				OrganizationId: (isInternalProduct ? null : currentOrg),
				OrganizationName: (isInternalProduct ? '' : `IDM ADMIN: ${currentOrg}`),
				ProductId: idmData.productId,
				ProductName, ProductLegacyName,
				DefaultSignInUrl, OrgNavigationUrlTemplate, Url,
				UserKey: null,
				Fake: true,
				Internal: isInternalProduct
			};

			filteredAccessRecords.push(fakeProductAccess);
			idmData.access.push(fakeProductAccess);
		}
	}

	// this account has no access at all
	if (idmData.access.length === 0) {
		/* eslint-disable no-console */
		if (console && console.log) {
			console.log('NO ACCESS RECORDS');
		}
		/* eslint-enable no-console */
		events.emit('idm.no-access', idmData.user);
		return;

	// couldn't find an access record for the current product
	} else if (filteredAccessRecords.length === 0) {
		return startAppOrgPicker(idmData);

		// if we have just one product access record that matches this app
	} else if (filteredAccessRecords.length === 1) {
		idmData.userContext = Object.assign({}, filteredAccessRecords[0]);

		// if we have more than one product access records that match this app
	} else if (filteredAccessRecords.length > 1) {

		// we are targeting a specific org
		if (currentOrg != null) {
			// try to find a legit product access record for the requested org
			const currentOrgProductAccess = filteredAccessRecords
				.find(a => currentOrg.toString() === a[_oid]);

			// if we couldn't find a real access record and we are not an idmAdmin
			if (currentOrgProductAccess == null) {
				// we have access to this app, but not for the org we are targeting
				// we are also not an idmAdmin
				return startAppOrgPicker(idmData);
			} else {
				idmData.userContext = Object.assign({}, currentOrgProductAccess);
			}

		// we are not targeting a specific org
		} else {
			// have the user pick the org they want to access
			return startAppOrgPicker(idmData);
		}
	}

	// if we have a user context and OrganizationUsers, aggregate the identities
	// that are within this organization context
	if (idmData.userContext != null && users.OrganizationUsers != null) {
		const {OrganizationId} = idmData.userContext;
		idmData.identities = users.OrganizationUsers.reduce(function (acc, ou) {
			if (ou.OrganizationId === OrganizationId) {
				acc = acc.concat(ou.Identities);
			}
			return acc;
		}, [].concat(users.Identities || []));
		// aggregate identities of consortium users as well
		if(users.ConsortiumUsers != null){
			idmData.identities = users.ConsortiumUsers.reduce(function (acc, cu) {
				cu.CrossOrgAppUserAssociations.forEach(xOrgAppAssoc => {
					if (xOrgAppAssoc.OrganizationId === OrganizationId) {
						xOrgAppAssoc.Access.forEach(access => {
							acc = acc.concat({Type: access.Type, Id: access.Id});
						});
					}
				});
				return acc;
			}, [].concat(idmData.identities || []));
		}
	}

	if (idmData.userContext != null) {
		// common deprecation attrs
		const obj = idmData.userContext;
		const objLoc = 'STORE(idm-data).userContext';

		deprecatedAttr({
			attr: 'FlId',
			newLoc: 'STORE(idm-data).user.profileId',
			obj, objLoc
		}, idmData.user.profileId.toString());

		deprecatedAttr({ attr: 'Description', obj, objLoc }, null);

		deprecatedAttr({ attr: 'UserKey', obj, objLoc }, function () {
			const idTypes = idmData.product.AssociatedRoles.map(x => x.Type);
			const identities = idmData.identities.filter(id => idTypes.includes(id.Type));
			return (identities[0] && identities[0].Id || null);
		});

		const deprecatedAccessAttrs = {
			'LegacyName': 'ProductLegacyName',
			'DefaultSignInUrl': 'Url',
			'OrgNavigationUrlTemplate': 'Url'
		};
		Object.keys(deprecatedAccessAttrs).forEach(function (attr) {
			const newAttr = deprecatedAccessAttrs[attr];
			deprecatedAttr({
				attr, obj, objLoc,
				newLoc: `STORE(idm-data).userContext.${newAttr}`
			}, () => idmData.userContext[newAttr]);
		});
	}

	store.set('idm-data', idmData);

	const domWin = dom.element(window);
	const idmAdminOverrideOfOrg = currentOrg !== state.currentOrg;
	const currentOrgIsNotTheOrgSelected = currentOrg !== idmData.userContext[_oid];

	if (idmAdminOverrideOfOrg || currentOrgIsNotTheOrgSelected) {
		updateCurrentOrgInSharedState();
	}
	domWin.on('focus', updateCurrentOrgInSharedState);

	events.on('_nav.expand-arrow', prefLeftNavExpanded => {
		setPrefLeftNavExpanded(prefLeftNavExpanded);
	});
};

function startAppOrgPicker(idmData, appOrgOverrides = {}) {
	store.set('pick-org-app-data', Object.assign({}, idmData, appOrgOverrides));
	store.set('idm-data', Object.assign(idmData, {userContext: {}}));
}

export const fillUiFromIdmData = function (idmData) {
	const productIdUpper = idmData.productId.toUpperCase();
	// idmData
	store.set('org-switcher-data', buildOrgSwitcherData(idmData));
	store.set('app-switcher-data', buildAppSwitcherData(idmData));
	store.set('user-data', idmData.user);

	events.on('header.org-switcher.change', function (org) {
		const orgId = org.id;
		const accessRecord = idmData.access
			.find(a => a[_pid].toUpperCase() === productIdUpper && a[_oid] === orgId);

		setCurrentOrgInSharedState(orgId, function () {
			if (accessRecord == null) {
				return startAppOrgPicker(idmData, {orgId});
			} else {
				window.location.href = accessRecord.Url;
			}
		});
	});
};

// shorthand get
export const get = function (url, cb, headers = []) {
	// if the user's session has expired, bail out!
	if (sessionHasExpired()) { return; }
	doCall('GET', url, null, createResponseHandler(cb), [{
		key: 'Authorization',
		value: `Bearer ${getBearerToken()}`
	}, {
		key: 'Accept',
		value: '*/*'
	}].concat(headers));
};

// shorthand post
export const post = function (url, data, cb = function () {}, headers = []) {
	// if the user's session has expired, bail out!
	if (sessionHasExpired()) { return; }
	doCall('POST', url, JSON.stringify(data), createResponseHandler(cb), [{
		key: 'Authorization',
		value: `Bearer ${getBearerToken()}`
	}, {
		key: 'Content-Type',
		value: 'application/json'
	}].concat(headers));
};

// shorthand put
export const put = function (url, data, cb, headers = []) {
	// if the user's session has expired, bail out!
	if (sessionHasExpired()) { return; }
	doCall('PUT', url, JSON.stringify(data), createResponseHandler(cb), [{
		key: 'Authorization',
		value: `Bearer ${getBearerToken()}`
	}, {
		key: 'Accept',
		value: '*/*'
	}].concat(headers));
};

// shorthand patch
export const patch = function (url, data, cb, headers = []) {
	// if the user's session has expired, bail out!
	if (sessionHasExpired()) { return; }
	doCall('PATCH', url, JSON.stringify(data), createResponseHandler(cb), [{
		key: 'Authorization',
		value: `Bearer ${getBearerToken()}`
	}, {
		key: 'Accept',
		value: '*/*'
	}].concat(headers));
};

// shorthand delete
export const del = function (url, cb = function () {}, headers = []) {
	// if the user's session has expired, bail out!
	if (sessionHasExpired()) { return; }

	doCall('DELETE', url, null, createResponseHandler(cb), [{
		key: 'Authorization',
		value: `Bearer ${getBearerToken()}`
	}, {
		key: 'Content-Type',
		value: 'application/json'
	}].concat(headers));
};

export const getAccessToken = getBearerToken;

export const setCurrentOrgInSharedState = function (currentOrg, cb) {
	const {idGatewayUrl} = store.get('idm');
	post(`${idGatewayUrl}/sessions/state`, {currentOrg}, cb);
};

function createResponseHandler(cb = () => {}) {
	return function (statusCode, responseText, headersStr, statusText) {
		// handle error state
		if (400 <= statusCode && statusCode <= 500) {
			cb(null);
			throw Error(statusText);
		}
		if (responseText && responseText.length) {
			cb(JSON.parse(responseText));
		} else {
			cb();
		}
	};
}

function sessionHasExpired() {
	// if we could not get the token, assume the user's session has expired
	if (getBearerToken() == null) {
		// side effect!!!!!
		store.set('session-timeout', {timer: 0});
		return true;
	}

	return false;
}

// remove extra access records that share the same OrgId and ProductId
// these are found in users that have multiple "roles" for a product
function filterDupProductAndOrgRecords (val, idx, arr) {
	return arr.findIndex(a => a[_oid] === val[_oid] && a[_pid] === val[_pid]) === idx;
}

function buildOrgSwitcherData(idmData) {
	const {userContext} = idmData;

	if (userContext[_oid] == null || userContext.Internal === true) {
		return { currentOrg: '', orgs: [] };
	}

	return {
		currentOrg: idmData.userContext[_oid].toString(),
		orgs: idmData.access
			.filter(a => !a.Disabled) // remove disabled
			.map(a => a[_oid]) // make an array of primitives
			.filter((v, i, a) => a.indexOf(v) === i) // so we can remove dupes
			.map(oId => { // then rebuild with id and name
				const {
					OrganizationId,
					OrganizationName
				} = idmData.access.find(a => a[_oid] === oId);

				return {
					id: OrganizationId.toString(),
					name: OrganizationName
				};
			})
	};
}

function buildAppSwitcherData(idmData) {
	const {userContext} = idmData;

	// if this is an internal app, setup the app switcher
	if (userContext.Internal === true) {
		return {
			currentApp: userContext.ProductId,
			apps: [{
				id: userContext.ProductId,
				name: userContext.ProductName
			}]
		};
	}

	if (userContext[_oid] == null) {
		return { currentApp: '', apps: [] };
	}

	const currentOrgId = idmData.userContext[_oid].toString();
	return {
		currentApp: idmData.userContext[_pid].toUpperCase(),
		apps: idmData.access
			.filter(a => !a.Disabled) // remove disabled
			.filter(a => a[_oid].toString() === currentOrgId)
			.filter(function(a, idx, arr) {
				return arr.findIndex(a2 => a2[_pid].toUpperCase() === a[_pid].toUpperCase()) === idx;
			})
			.map(a => { // then rebuild with id and name
				const { ProductId, ProductName } = a;
				var url = a.Url;
				return { id: ProductId.toUpperCase(), name: ProductName, url, legacyName: a.ProductLegacyName };
			})
	};
}

function updateCurrentOrgInSharedState() {
	const idmData = store.get('idm-data');
	setCurrentOrgInSharedState(idmData.userContext[_oid]);
}

function getBearerToken() {
	const idmCfg = store.get('idm');
	// handle old accessToken prop and provide deprecation message
	if (idmCfg.accessToken == null && typeof idmCfg.getAccessToken === 'function') {
		return idmCfg.getAccessToken();
	} else {
		/* eslint-disable no-console */
		if (console && console.error) {
			console.error('DEPRECATED! sidekick.store: idm.accessToken is deprecated, please provide a getAccessToken function that returns the current, valid, idm access token');
		}
		/* eslint-enable no-console */
		return idmCfg.accessToken;
	}
}

function setPrefLeftNavExpanded(prefLeftNavExpanded, cb) {
	const {idGatewayUrl} = store.get('idm');
	post(`${idGatewayUrl}/sessions/state`, {prefLeftNavExpanded}, cb);
}
