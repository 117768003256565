import * as dom from '../../util/easy-dom';
import {cmptMenu} from '../../util/cmpt-common-features';
import * as store from '../../util/store';
import * as events from '../../util/event-bus';

/**
 * Displays notifications
 * @class Sidekick.Notifications
 * @memberOf Sidekick
 * @param {EasyDom} elem - Container {@link EasyDom} element
 * @param {Object} attrs
 * @param {Object} ctx
 * @param {Function} i18n - translation function
 * @emits notification.delete
 * @emits notification.mark-as-read
 * @listens store notifications
 * @example Array of notifications
 * title: Notification Title
 * content: Notification Content
 * unread: Optional. Shows the unread dot
 * timestamp: Optional. Shows a timestamp
 * {
	title: 'this is notification 1',
	content: 'You got notified...',
	unread: true
}, {
	title: 'this is notification 2',
	content: 'You got notified... again!',
	timestamp: 'TODAY'
}
 */
export default function (elem, attrs, ctx, i18n) {
	const notificationsEmpty = dom.parse(`<li class="menulist-item notifications-empty"><div>${i18n('You have no notifications')}</div></li>`);

	/////
	// find dom elements that we care about
	//
	const domNotiCont = elem.find('.menulist')[0];
	const domNotiMenu = elem.find('.menu')[0];
	const domNotiBell = elem.find('.control')[0];
	const domMenuCloseIcon = elem.find('.ss-ctrl-clear')[0];
	const domUnreadBadge = domNotiBell.find('.sk--unread')[0];

	/////
	// handle iniital state and data changes
	//

	// if url is present just make the button a link
	if (ctx.url != null) {
		domNotiBell.attr('target', ctx.target);
		events.on('notifications.unread-count', setUnreadBadge, true);
		return;
	}

	store.onValue('notifications', updateNotificationList);

	/////
	// listen for events and take action
	//
	cmptMenu(elem, domNotiBell, domNotiMenu);
	domMenuCloseIcon.on('click', () => events.emit('sk-menu-close', elem));
	events.on('sk-menu-close', function (e) {
		if (e !== elem) { return; }
		domNotiCont.find('=a').forEach(a => a.attr('tabindex', '-1'));
		domNotiCont.find('=button').forEach(b => b.attr('tabindex', '-1'));
	});
	events.on('sk-menu-open', function (e) {
		if (e !== elem) { return; }
		domNotiCont.find('=a').forEach(a => a.attr('tabindex', '0'));
		domNotiCont.find('=button').forEach(b => b.attr('tabindex', '0'));
	});

	/////
	// update function for when notification list changes
	//
	/**
	 * Updates the display with the new notifications being passed in.
	 * @param  {Array} nArr - New context object with new notifications.
	 */
	function updateNotificationList(newCtx) {

		// maintain backward compatability with ctx as an array
		if (Object.prototype.toString.call(newCtx) === '[object Array]') {
			newCtx = {items: newCtx};
		}

		// update our context object
		if (ctx.items !== newCtx.items) { ctx = newCtx; }

		// update the unread count
		updateUnread();

		// no items to display
		if (ctx.items.length === 0) { domNotiCont.children(notificationsEmpty); return; }

		// process the list of items to display
		domNotiCont.children(ctx.items.map(item => {
			const el = dom.parse(`
				<li class="menulist-item ${item.unread ? 'unread' : ''}">
						<h4 class="subject">
							${item.unread ? '<a class="mark-read" href="#" aria-label="mark as read"></a>' : ''}
							${item.title}
						</h4>
						<div>${item.content}</div>
						${item.timestamp ? `<small>${item.timestamp}</small>` : ''}
						<button type="button" class="remove fa-li-close" aria-label="remove notification"></button>
					</a>
				</li>
			`);

			el.find('.remove')[0].on('click', evt => {
				evt.preventDefault();
				evt.stopPropagation();
				events.emit('notification.delete', item);
			});

			if (item.unread) {
				el.find('.mark-read')[0].on('click', function (evt) {
					evt.preventDefault();
					evt.stopPropagation();
					events.emit('notification.mark-as-read', item);
					updateUnread();
				});
				el.on('click', function (evt) {
					evt.stopPropagation();
					events.emit('notification.mark-as-read', item);
				});
			}

			el.on('click', function (evt) {
				evt.stopPropagation();
				events.emit('notification.action', item);
			});

			return el;
		}));

		if (!domNotiMenu.hasClass('ss-menu-open')) {
			domNotiCont.find('=a').forEach(a => a.attr('tabindex', '-1'));
			domNotiCont.find('=button').forEach(b => b.attr('tabindex', '-1'));
		}
	}

	function updateUnread() {
		let unread = ctx.items.filter(n => n.unread === true).length;
		setUnreadBadge(unread);
	}

	function setUnreadBadge(unread) {
		elem.toggleClass('has-unread', unread > 0);
		domUnreadBadge.css('display', 'none');
		domUnreadBadge.removeClass('sk--max');
		if (unread > 0) {
			if (unread > 99) {
				domUnreadBadge.addClass('sk--max');
				unread = 99;
			}
			domUnreadBadge._.innerHTML = unread;
			domUnreadBadge.css('display', 'block');
		}
	}
};
