/* globals ambient */
import * as dom from '../../util/easy-dom';
import * as events from '../../util/event-bus';
import {pathMatchesRoute} from '../../util/route-helpers';

export default function (elem, attrs, ctx, i18n) {
	// height values for different measurements
	const [titleHeight, itemHeight] = [54, 30];
	const [bottomPad, headerHeight] = [30, 60];
	const singleColumnHeight = ctx.reduce(function (total, titleNode) {
		return total + titleHeight + (itemHeight * titleNode.children.length);
	}, headerHeight + bottomPad);
	var singleColumnMode = true;

	const menuContainer = elem.find('.mega')[0];
	const menuItems = ctx.map(function(item, idx) {
		// backward compatability with item.route value
		item.url = item.url || item.route || null;

		const el = dom.parse(`<ul class="menu-group" role="menu"><li class="group-title">${item.title}</li></ul>`);

		// add menuName value if present
		if (typeof item.menuName === 'string') {
			el.data('menu-name', item.menuName);
		}

		el.append(item.children.map(function (child, cidx) {
			// backward compatability with item.route value
			child.url = child.url || child.route || null;

			const href = child.url || '#';
			const target = child.url && child.target ? ` target="${child.target}"` : '';
			const childEl = dom.parse(`
				<li class="sub-menu-item sk--child-${idx}-${cidx}">
					<a class="sub-menu-item-container" title="${child.title}" href="${href}"${target} role="menu-item">
						<span>${child.title}</span>
					</a>
				</li>
			`);

			// add menuName value if present
			if (typeof child.menuName === 'string') {
				childEl.data('menu-name', child.menuName);
			}

			childEl.find('.sub-menu-item-container')[0].on('click', function(evt) {
				events.emit('nav.menu-item-clicked', child, evt);
			});

			return childEl;
		}));

		return el;
	});
	menuContainer.children(menuItems);

	ambient.on('update', 'sk-adjust-menu-columns', (type, cls, v) => adjustColumns(v.height));
	adjustColumns(ambient.getPluginValue('height'));
	events.on('_location.path-change', highlightRoute);

	function adjustColumns(height) {
		// store the current state
		const currentlySingleCol = singleColumnMode;
		// check the new state
		singleColumnMode = height >= singleColumnHeight;
		// only involve the DOM if there's a change needed
		if (currentlySingleCol !== singleColumnMode) {
			elem.toggleClass('sk--two-column', !singleColumnMode);
		}
	}

	function highlightRoute(path) {
		if (path == null) { return; }
		ctx.forEach(function (item, idx) {
			item.children.forEach(function (child, cidx) {
				const highlighted = pathMatchesRoute(path, child.url, child.routeMatcher);
				elem.find(`.sk--child-${idx}-${cidx}`)[0].toggleClass('highlight', highlighted);
			});
		});
	}
};
