import * as store from './store';

/**
 * @module Sidekick.DomScanner
 * @memberOf Sidekick
 */

let titleInterval = null;

/**
 * Scans the document title to append app name if not found.
 * @function title
 * @instance
 */
export function title() {
	stopTitle();

	store.onValueOnce('app-switcher-data',() => {
		titleInterval = setInterval(() => {
			if (store.get('app-switcher-data') == null) { return; }

			const {currentApp, apps} = store.get('app-switcher-data');
			if (currentApp == null || apps == null || apps.length === 0) { return; }

			const appName = apps.find(a => a.id === currentApp).name;

			if (document.title.endsWith(appName)) { return; }

			document.title = `${document.title} - ${appName}`;
		}, 500);
	});
}

/**
 * Stops scanning the title
 * @function stopTitle
 * @instance
 */
export function stopTitle() {
	if (titleInterval != null) {
		clearInterval(titleInterval);
		titleInterval = null;
	}
}
