import * as dom from '../../util/easy-dom';
import * as store from '../../util/store';

/**
 * Displays Organization information
 * @class Sidekick.ContactOrg
 * @memberOf Sidekick
 * @param {EasyDom} elem - Container {@link EasyDom} element
 * @param {Object} attrs
 * @param {Object} ctx
 * @param {Function} i18n - translation function
 * @listens current-org - Accepts an organization to update the information
 */
export default function(elem, attrs, ctx, i18n) {
	store.onValue('current-org', fillContent);

	function fillContent(newCtx) {
		if (ctx !== newCtx) { ctx = newCtx; }

		let phones = '';

		if (ctx.phone != null && ctx.phone.length) {
			phones = `${i18n('Phone')}: ${ctx.phone}`;
		}

		if (ctx.fax != null && ctx.fax.length) {
			if (phones.length) {
				phones += ', ';
			}
			phones += `${i18n('Fax')}: ${ctx.fax}`;
		}

		elem.children(dom.parse(`<dl class="sk--org">
			<dt><h4>${i18n('Contact Your Organization')}</h4></dt>
			<dd class="sk--name">${ctx.name}</dd>
			${ctx.contactName != null && ctx.contactName.length ? `<dd class="sk--contact-name">${ctx.contactName}</dd>` : ''}
			${phones.length ? `<dd class="sk--phones">${phones}</dd>` : ''}
			${ctx.email != null ? `<dd class="sk--email"><a href="mailto:${ctx.email}">${ctx.email}</a></dd>` : ''}
			${ctx.comments != null && ctx.comments.length ? `<dd class="sk--comments">${ctx.comments}</dd>` : ''}
		</dl>`));
	}
};
