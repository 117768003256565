import * as store from './store';
import * as events from './event-bus';
import * as env from './env';
import * as persist from './persistence';
import {deprecatedAttr} from './deprecated';

import {
	get, post, put, patch, del,
	processIdmData,
	fillUiFromIdmData,
	setCurrentOrgInSharedState
} from './idm-helpers';

// common persistence config
const cache1DayLocal = {
	type: 'local', // local storage
	expires: 1440, // 1 day
};

var idmData = {};
store.onValueOnce('idm', handleIdmConfig);
store.onValueOnce('idm', d => events.emit('token-ready', {get, post, put, patch, del}));
store.onValueOnce('idm-data', fillUiFromIdmData);
events.on('_pick-org-app.selected', function ({orgId, productId}) {
	if (orgId == null || productId == null || !idmData.access) { return; }

	const accessRecord = idmData.access
		.find(({OrganizationId, ProductId} = {}) => (
			OrganizationId != null
			&& ProductId != null
			&& OrganizationId.toString() === orgId.toString()
			&& ProductId.toUpperCase() === productId.toUpperCase()
		));

	if (accessRecord == null) { return; }

	setCurrentOrgInSharedState(accessRecord.OrganizationId, function () {
		location.href = accessRecord.Url;
	});
});

function handleIdmConfig(cfg) {
	let {idGatewayUrl, flId, currentOrg, product} = cfg;
	const idmMeta = {
		targetOrgId: currentOrg,
		users: null
	};
	const envName = env.environmentFromIdmGatewayUrl(idGatewayUrl);
	const envValues = env[envName];

	if (idGatewayUrl.endsWith('/')) {
		cfg.idGatewayUrl = idGatewayUrl = idGatewayUrl.slice(0, -1);
	}

	store.onValueOnce('idm-data', function (data) {
		if (data.userContext == null) { return; }
		if (data.userContext.OrganizationId == null) { return; }
		const orgId = data.userContext.OrganizationId;

		/////
		// get the org-co-branding data from the org service
		//

		const envSandApiUrl = envValues.ecSand;

		const rawOrgBrandData = persist.get(`rawOrgBrand-${orgId}`);
		if (rawOrgBrandData == null) {
			const orgBrandUrl = `${envSandApiUrl}/OrganizationAPI/api/organizations/${orgId}?fields=branding`;
			get(orgBrandUrl, function (orgBrandRes) {
					persist.set(`rawOrgBrand-${orgId}`, orgBrandRes, cache1DayLocal);
					processOrgBrand(orgBrandRes);
				}, [{
					key: 'FlUser',
					value: `{"OrganizationId": "${orgId}"}`
				}]
			);
		} else {
			processOrgBrand(rawOrgBrandData);
		}
		function processOrgBrand(brand) {
			store.set('org-branding-data', brand.data.attributes.branding);
		}
	});

	let rawProduct = persist.get(`rawProduct-${product.toUpperCase()}`);
	if (product.length > 0 && rawProduct == null) {
		get(`${idGatewayUrl}/api/products/${product}`, function (pData) {
			persist.set(`rawProduct-${product.toUpperCase()}`, pData, cache1DayLocal);
			processIdmData(Object.assign(idmData, {product: pData}), idmMeta);
		});
	} else {
		processIdmData(Object.assign(idmData, {product: rawProduct}), idmMeta);
	}

	/////
	// get the product data and push it into the store
	//
	get(`${idGatewayUrl}/sessions`, function (session) {
		processIdmData(Object.assign(idmData, { session }), idmMeta);
		getSessionData(session.Id);
	});

	function getSessionData(sid) {
		/////
		// Process User Data
		//
		var rawUser = persist.get(`rawUser-${flId}-${sid}`);
		var rawProductLinks = persist.get(`rawProductLinks-${flId}-${sid}`);

		// get user data
		if (rawUser == null) {
			const userUrl = `${idGatewayUrl}/userservice/v0/api/users/id/${flId}`;
			get(userUrl, function (userRes) {
				persist.set(`rawUser-${flId}-${sid}`, userRes, cache1DayLocal);
				rawUser = userRes;
				processUsersAndProducts(rawUser, rawProductLinks);
			});
		} else {
			processUsersAndProducts(rawUser, rawProductLinks);
		}

		// get product links data
		if (rawProductLinks == null) {
			const productLinksUrl = `${idGatewayUrl}/userservice/v0/api/productlinks?userId=${flId}`;
			get(productLinksUrl, function (productLinksRes) {
				persist.set(`rawProductLinks-${flId}-${sid}`, productLinksRes, cache1DayLocal);
				rawProductLinks = productLinksRes;
				processUsersAndProducts(rawUser, rawProductLinks);
			});
		} else {
			processUsersAndProducts(rawUser, rawProductLinks);
		}

		function processUsersAndProducts(users, products) {
			if (users == null || products == null) { return; }

			const data = {productId: product};
			idmMeta.users = users;

			// set roles
			data.roles = users.Roles.map(r => r.Name);

			// set user
			data.user = {
				profileId: users.Id,
				firstName: users.FirstName,
				lastName: users.LastName,
				email: users.Email,
				emailConfirmed: users.EmailIsVerified,
				roles: data.roles,
				isFrontlineUser: (users.IsFrontlineUser === true),
			};

			// set deprecated user fields
			const removedUserAttrs = [
				'username',
				'middleName',
				'birthday',
				'gender',
				'militaryStatus',
				'emailSent'
			];
			removedUserAttrs.forEach(function (attr) {
				const objLoc = 'STORE(idm-data).user';
				deprecatedAttr({obj: data.user, attr, objLoc}, null);
			});

			// set access
			data.access = products.map(function (p) {
				const access = Object.assign({ Disabled: false }, p);

				// deprecation defaults
				const obj = access;
				const objLoc = 'STORE(idm-data).access[*]';

				deprecatedAttr({
					obj, objLoc, attr: 'FlId',
					newLog: 'STORE(idm-data).user.profileId'
				}, flId);

				deprecatedAttr({ obj, objLoc, attr: 'Description' }, null);

				deprecatedAttr({ obj, objLoc, attr: 'UserKey' }, function () {
					const idType = envValues.productUserTypeMap[access.ProductId];
					const {OrganizationId} = access;
					const identities = users.OrganizationUsers.reduce(function (acc, ou) {
						if (ou.OrganizationId === OrganizationId) {
							acc = acc.concat(ou.Identities.filter(id => id.Type === idType));
						}
						return acc;
					}, []);
					return (identities[0] && identities[0].Id || null);
				});

				const deprecatedAccessAttrs = {
					'LegacyName': 'ProductLegacyName',
					'DefaultSignInUrl': 'Url',
					'OrgNavigationUrlTemplate': 'Url'
				};
				Object.keys(deprecatedAccessAttrs).forEach(function (attr) {
					const newAttr = deprecatedAccessAttrs[attr];
					const newLoc = `STORE(idm-data).access[*].${newAttr}`;
					deprecatedAttr({ obj, objLoc, attr, newLoc }, () => access[newAttr]);
				});

				return access;
			});

			processIdmData(Object.assign(idmData, data), idmMeta);
		}
	}
}
