import * as dom from '../../util/easy-dom';
import * as events from '../../util/event-bus';
import * as persist from '../../util/persistence';
import { glean } from '../../util/object-helpers';
import { detect } from 'detect-browser';

const _win = (0, eval)('this'); // eslint-disable-line
const _log = glean(_win, x => x.console.log.bind(x.console), () => {});
const supportedBrowsers = [
    {name: 'chrome', version: 50},
    {name: 'firefox', version: 40},
    {name: 'safari', version: 11, os: 'mac os'},
    {name: 'ie', version: 11},
    {name: 'edge'},
    {os: 'ios'}
];

export default function (elem, attrs, ctx, i18n) {
    const browser = detect();
    const alreadyDismissed = persist.get('browser-warning-dismissed');
    // console.log('alreadyDismissed =====> ', alreadyDismissed);
    if (alreadyDismissed === true) { return; }
    const name = glean(browser, x => x.name.toLowerCase());
    // console.log('NAME =====>', name);
    const os = glean(browser, x => x.os.toLowerCase());
    // console.log('OS =====>', os);
    const version = glean(browser, x => parseInt(x.version.split('.')[0], 10), 0);
    // console.log('VERSION =====>', version);
    const isSupported = supportedBrowsers.some(b => (
        (!!name && (b.name || name) === name)
        && (!!os && (b.os || os) === os)
        && (!!version && (b.version || 0) <= version)
    ));

    if (isSupported) { return; }
    _log('UNSUPPORTED BROWSER DETECTED', {
        name, os, version,
        userAgent: glean(_win, x => x.navigator.userAgent, 'UserAgent Not Found')
    });

    const domBody = dom.element(_win.document.body);
    const [domDismissBtn] = elem.find('button');

    domBody.addClass('sk-unsupported-browser');

    const dismiss = () => {
        persist.set('browser-warning-dismissed', true, { expires: null });
        elem.css({ position: 'absolute', left: '0' });
        dom.rAF(() => {
            domBody.addClass('sk-unsupported-browser-dismissed');
            setTimeout(() => elem.css({ display: 'none' }), 550);
        });
    };

    events.on(
        'nav.expanded-footprint',
        state => elem.toggleClass('sk--nav-expanded-footprint', state)
    );

    domDismissBtn.on('click', dismiss);
};
