export const activityIndicator = (ctx, attrs, i18n) => `<a href="${ctx.url}"><span sk-tooltip content="${i18n('System Activity')}"></span> <span class="sk--dots"><span class="sk--dot"></span> <span class="sk--dot"></span> <span class="sk--dot"></span></span></a>`;
export const announcements = (ctx, attrs, i18n) => `<div class="sk-announcements-container sk-hidden"><div class="sk-announcements-warning"><span class="fa-warning"></span></div><div class="sk-announcements-message"><div class="sk-announcements-title"></div><div class="sk-announcements-content"></div><a class="sk-announcements-anchor sk-hidden" href="" target="_blank">Learn more</a></div><div class="sk-announcements-close"><button class="close" aria-label="close announcement"><span class="fa-times"></span></button></div></div>`;
export const appSwitcher = (ctx, attrs, i18n) => `<div sk-skip-nav context-store="sk-config"></div><button id="sk--app-switcher-title" class="app-title" tabindex="0" aria-controls="sk--app-switcher-menu" aria-pressed="false"><div class="sk--content"></div><span sk-tooltip content="${i18n('Switch Apps')}"></span></button><div id="sk--app-switcher-menu" class="sk--menu" role="menu" aria-hidden="true" aria-describedby="sk--app-switcher-instruction"><h3 id="sk--app-switcher-instruction" class="sk-instruction">${i18n('Your Solutions')}</h3><ul class="sk--menulist"></ul></div>`;
export const contactOrg = (ctx, attrs, i18n) => ``;
export const footer = (ctx, attrs, i18n) => `<div id="override-passport-footer"></div>`;
export const header = (ctx, attrs, i18n) => `<div class="sk--header-content" role="menubar"><div sk-announcements context-store="idm-data"></div><div sk-impersonated-user context-store="user-data"></div><div class="frontline-logo fa-logo-frontline-small"></div><div sk-app-switcher context-store="app-switcher-data" role="menuitem" aria-haspopup="true"></div><div sk-org-switcher context-store="org-switcher-data" role="menuitem" aria-haspopup="true"></div><div class="sk--impersonation-border-lr"></div><div class="sk--impersonation-border-b"></div><div class="sk--content-right"><div sk-activity-indicator context-store="activity-indicator"></div><div sk-search context-store="search"></div><div sk-help context-store="help" role="menuitem" aria-haspopup="true"></div><div sk-user-info context-store="user-data" role="menuitem" aria-haspopup="true"></div><div sk-notifications context-store="notifications"></div><button class="sk--impersonation-exit fa-li-close"></button></div></div>`;
export const help = (ctx, attrs, i18n) => `<button id="sk--help-menu-title" class="control fa-li-question-circle-before" aria-controls="sk--help-menu" aria-pressed="false" aria-label="${i18n('Help Resources')}"><span sk-tooltip content="${i18n('Help Resources')}"></span></button><div id="sk--help-menu" class="menu" role="menu" aria-hidden="true"><h3 id="sk--help-menu-instruction" class="sk-instruction">${i18n('Help Resources')}</h3><div class="help-org-contact" sk-contact-org context-store="current-org"></div><ul class="articles menulist"></ul><ul class="blocks menulist"></ul></div>`;
export const impersonatedUser = (ctx, attrs, i18n) => `<div sk-skip-nav context-store="sk-config"></div><div class="sk--viewing-as">${i18n('viewing as')}</div><div class="sk--user-name">${ctx.firstName} ${ctx.lastName}</div>`;
export const loader = (ctx, attrs, i18n) => `<div class="sk-loading-container">${!!attrs.statusText ? `<div class="sk-status">${attrs.statusText}</div>` : ''}<div class="sk-logo-spinner"><span class="sk-bar sk-bar-1"></span> <span class="sk-bar sk-bar-2"></span> <span class="sk-bar sk-bar-3"></span> <span class="sk-bar sk-bar-4"></span> <span class="sk-bar sk-bar-5"></span> <span class="sk-bar sk-bar-6"></span></div></div>${!!attrs.fill ? `<div class="sk-cover"></div>` : ''}`;
export const nav = (ctx, attrs, i18n) => `<div class="sk--content-dimmer"></div><div class="sk--back-drop"><span class="sk--fl-pattern"></span></div><div class="sk--org-branding"><div class="sk--org-brand-small"></div><div class="sk--org-brand-large"></div></div><div class="sk--nav-title">${ i18n('Navigation') }</div><i class="sk--nav-arrow" tabindex="0" aria-describedby="sk--nav-arrow-tooltip"><span sk-tooltip class="sk--nav-arrow-tooltip" id="sk--nav-arrow-tooltip" content="${ i18n('Toggle Navigation') }" position="right"></span></i><div class="sk--top-section"><ul class="sk--menu-items" role="menu"></ul></div><div class="sk--bottom-section"><div class="sk--frontline-branding"><div class="sk--logo-container sk--full-logo"><a class="fa fa-logo-frontline sk--frontline-logo" target="_blank" href="http://frontlineeducation.com"></a> <span class="sk--copyright-info">&copy; ${(new Date()).getFullYear()} | ${ i18n('All rights reserved') }</span></div><div class="sk--logo-container sk--small-logo"><a class="fa fa-logo-frontline-small sk--frontline-logo" target="_blank" href="http://frontlineeducation.com" tabindex="-1"></a> <span class="sk--copyright-info">&copy; ${(new Date()).getFullYear()}</span></div></div></div>`;
export const navItem = (ctx, attrs, i18n) => `<a class="sk--main-menu-item-container" href="${ctx.url || '#'}" target="${ctx.url && ctx.target ? ctx.target : '_self'}" role="menuitem"><i class="sk--nav-icon fa ${ctx.icon}"></i> <span class="sk--badge"></span> <span class="sk--main-menu-title">${ctx.title}</span> <span sk-tooltip content="${ctx.title}" position="right"></span> ${ctx.children && ctx.children.length ? `<i class="sk--more-items fa fa-angle-right"></i>` : ''}<div class="sk--hover-elem"></div></a>`;
export const navItemMegaMenu = (ctx, attrs, i18n) => `<div class="mega"></div>`;
export const navItemSubmenu = (ctx, attrs, i18n) => `<div class="no-mega"><ul class="menu-group" role="menu"></ul></div>`;
export const notifications = (ctx, attrs, i18n) => `${ctx.url == null ? `<button class="control" aria-label="${i18n('notifications')}"><span class="fa-li-bell-before"></span> <span class="sk--unread"></span> <span sk-tooltip content="${i18n('Notifications')}" arrow-shift="1px"></span></button>` : `<a href="${ctx.url}" class="control" aria-label="${i18n('notifications')}"> <span class="fa-li-bell-before"></span> <span class="sk--unread"></span> <span sk-tooltip content="${i18n('Notifications')}" arrow-shift="1px"></span> </a>` }<div class="menu"><h3 class="sk-instruction">${i18n('Recent Notifications')} <button type="button" tabindex="-1" class="ss-ctrl-clear fa-li-arrow-right2" aria-label="${i18n('close panel')}"></button></h3><ul class="menulist"></ul></div>`;
export const orgSwitcher = (ctx, attrs, i18n) => `<button id="sk--org-switcher-title" class="control" aria-controls="sk--org-switcher-menu" aria-pressed="false"><div class="content"></div><span sk-tooltip content="${i18n('Switch Organizations')}"></span></button><div id="sk--org-switcher-menu" class="menu" role="menu" aria-hidden="true" aria-describedby="sk--org-switcher-instruction"><h3 id="sk--org-switcher-instruction" class="sk-instruction">${i18n('Select an Organization')}</h3><div class="search"><div class="sk-input"><span class="sk--input-container fa-li-search-before"><input type="text" class="search-org" placeholder="${i18n('Search')}" autocomplete="off"> </span><button type="button" tabindex="-1" class="ss-ctrl-clear fa-li-close" aria-label="${i18n('clear input')}"></button></div></div><div class="menu-scroll"><ul class="menulist"></ul></div><div class="load-all-wrapper"><span class="load-all-btn">Load All</span></div></div>`;
export const pickApp = (ctx, attrs, i18n) => `<h2 class="sk--filtered-org-name"></h2><h1>${i18n('Select an Application')}</h1><ul class="sk--org-app-list"></ul>`;
export const pickOrg = (ctx, attrs, i18n) => `<h2 class="sk--filtered-app-name sk-hidden"></h2><h1>${i18n('Select an Organization')}</h1><div class="sk-input search-container"><span class="sk--input-container fa-li-search-before"><input class="search" type="text" value="" placeholder="${i18n('Search Organizations')}"> </span><button type="button" tabindex="-1" class="ss-ctrl-clear fa-li-close sk-hidden" aria-label="${i18n('clear input')}"></button></div><ul class="sk--org-app-list"></ul><div class="load-all-wrapper"><span class="load-all-btn">Load All</span></div>`;
export const pickOrgApp = (ctx, attrs, i18n) => `<div class="sk--picker-user-info"><div sk-user-info context-store="user-data"></div></div><div class="sk--panel-left"><i class="fa-logo-frontline"></i><div sk-pick-org context-store="pick-org-app-data"></div><div sk-pick-app context-store="pick-org-app-data"></div></div>`;
export const search = (ctx, attrs, i18n) => `<div class="sk-input"><span class="sk--input-container fa-li-search-before"><input class="search" type="text" value=""> </span><button type="button" class="ss-ctrl-clear fa-li-close" aria-label="${i18n('clear input')}"></button></div><div class="sk--menu"><h3 id="sk-search-results-title" class="sk-instruction">${i18n('Search Results')}</h3><div class="sk--loader" role="presentation"><div sk-loader fill></div></div><div class="sk--search-content"></div></div>`;
export const sessionTimeout = (ctx, attrs, i18n) => `<div class="sk--timer-mode"><div class="sk--content-dimmer"></div><div class="sk--session-timeout-modal modal"><div class="title">${i18n('You will be signed out soon.')}</div><div class="sk--timer"></div><div class="content">${i18n('Due to inactivity, your session will end and you will be signed out. Do you want to stay signed in?')}</div><div class="modal-button"><a class="sk-button sk-button-std sk--logout" href="#">${i18n('No, Sign Me Out')}</a> <a class="sk-button sk-button-pri sk--extend" href="#">${i18n('Yes, Stay Signed In')}</a></div></div></div><div class="sk--signed-out"><div class="sk--content"><div class="sk--logo"></div><h2>${i18n('You have been signed out.')}</h2><p>${i18n('For your security and protection of your data, you have been signed out due to inactivity.')}</p><div><a href="#" class="sk--sign-in-link">${i18n('Sign In Again to Continue')}</a></div></div></div>`;
export const siteErrors = (ctx, attrs, i18n) => `<div class="sk--content"><div class="sk--logo"></div><h2>Something went wrong.</h2><p>We don’t know what happened but something went wrong. Please wait a few minutes then try to sign in again.</p><div><a href="#" class="sk--sign-in-link">Sign In Again to Continue</a></div></div>`;
export const skipNav = (ctx, attrs, i18n) => `<a class="sk--skip-nav-link" href="#${ctx.mainId ? ctx.mainId : ''}">${i18n('Skip to Main Content')}</a>`;
export const tooltip = (ctx, attrs, i18n) => `<span class="sk-tip-chevron"></span> <span class="sk-tip-content">${attrs.content}</span>`;
export const unsupportedBrowserAlert = (ctx, attrs, i18n) => `<div class="sk--unsupported-browser-message"><a href="https://help.frontlineeducation.com/hc/en-us/articles/360001607068" target="_blank" class="sk--message">Your web browser is not supported and may cause problems. <span>Learn More</span> </a><button><i>&#xe909;</i> <span>Dismiss</span></button></div>`;
export const userInfo = (ctx, attrs, i18n) => `<button id="sk--user-info-title" class="sk--control" aria-controls="sk--user-info-menu" aria-pressed="false" aria-label="${ctx.firstName} ${ctx.lastName}"><span class="sk--user-name fa-angle-down-after" title="${ctx.firstName} ${ctx.lastName}">${ctx.firstName} ${ctx.lastName}</span> <span class="sk--current-role"></span></button><div id="sk--user-info-menu" class="sk--menu" role="menu" aria-hidden="true" aria-describedby="sk--user-info-instruction"><h3 id="sk--user-info-instruction" class="sk-instruction sk--fl-account-header">${i18n('Your Frontline Account')}</h3><div class="sk--filter"><span class="fa-li-search-before"><input class="sk--roles-filter" type="text" value="" aria-label="Type to filter" placeholder="Type to filter"></span></div><ul class="sk--roles sk--menulist"></ul><ul class="sk--blocks sk--menulist"><li><a class="sk--settings fa-li-settings" href="#" role="menuitem">${i18n('Account Settings')}</a></li><li class="sk--passport"><a class="sk--frontline-home fa-li-home" href="#" role="menuitem">${i18n('My Frontline')}</a></li><li class="sk--passport"><a class="sk--end-impersonation fa-li-close" href="#" role="menuitem">${i18n('Stop Impersonation')}</a></li><li><a class="sk--logout fa-li-power" href="#" role="menuitem">${i18n('Sign Out')}</a></li></ul></div>`;