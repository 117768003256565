export default function (elem, attrs, ctx, i18n) {
	const {mainElement} = ctx;
	const [domSkipLink] = elem.find('.sk--skip-nav-link');

	if (domSkipLink == null) { return; }

	domSkipLink.on('focus', () => {
		elem.addClass('sk-skip-nav-focused');
	});

	domSkipLink.on('blur', () => {
		elem.removeClass('sk-skip-nav-focused');
	});

	domSkipLink.on('click', onClick);

	function onClick(evt) {
		evt.preventDefault();

		let targetElement;

		if (mainElement instanceof Element) {
			targetElement = mainElement;
		} else {
			targetElement = document.querySelector('main, [role=main]');
			if (targetElement == null) {
				targetElement = ctx.appElement;
			}
		}

		if (targetElement.tabIndex === -1 && targetElement.getAttribute('tabindex') == null) {
			targetElement.setAttribute('tabindex', '-1');
		}

		targetElement.focus();
	}
};
