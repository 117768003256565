import * as dom from '../../util/easy-dom';
import * as store from '../../util/store';
import {cmptMenu, cmptMenuItem} from '../../util/cmpt-common-features';
import * as events from '../../util/event-bus';

/**
 * Displays articles, and helper blocks
 * @class Sidekick.Help
 * @memberOf Sidekick
 * @param {EasyDom} elem - Container {@link EasyDom} element
 * @param {Object} attrs
 * @param {Object} ctx
 * @param {Function} i18n - translation function
 * @emits header.help.article.click - Thrown when an article is clicked that doesn't have a url
 * @emits header.help.block.click - Thrown when an block is clicked that doesn't have a url
 * @example
 * articles: List of articles pertaining to the app
 * blocks: List of other links to different sections of the app.
 * title: Text to show the user
 * icon: Icon to show the user
 * url: Optional. If passed article/block will open a new window to that url. If omitted then
 * 	header.help.article.click/header.help.block.click will be thrown and the item will be the article/block. Extra
 * 	params can optionally be added to the item when building the nav menu and they will be included back to you when the
 * 	nav item is clicked.
 * {
	'articles': [{
		'title': 'Getting Started with Frontline Central',
		'url': '/getting_started',
		'icon': 'file-text'
	}],
	'blocks': [{
		'title': 'Learn more about Frontline Central',
		'url': 'http://help1.frontlinek12.com/?b_id=13067',
		'icon': 'learning_center_logo'
	}]
}
 */
export default function(elem, attrs, ctx, i18n) {
	/////
	// find dom elements that we care about
	//
	const [domButton] = elem.find('.control');
	const [domMenu] = elem.find('.menu');
	const [domBlocks] = elem.find('.blocks');
	const [domArticles] = elem.find('.articles');

	// initialize with default values
	let urlTemplateVars = getUrlTemplateVars();

	/////
	// handle initial state and data changes
	//
	updateHelpData(ctx);
	store.onValue('help', updateHelpData);

	store.onValue('idm-data', idmData => {
		urlTemplateVars = getUrlTemplateVars(idmData);
		updateHelpData(ctx);
	});

	/////
	// listen for events and take action
	//
	cmptMenu(elem, domButton, domMenu);

	/////
	// helper functions
	//
	/**
	 * Updates the display with the new data being passed in.
	 * @param  {Object} newCtx - New context object with the new data.
	 */
	function updateHelpData(newCtx) {
		// update our context object
		if (ctx !== newCtx) { ctx = newCtx; }

		// articles
		var articles = ctx.articles;
		if (articles == null || articles.length === 0) {
			// no articles to display
			domArticles.css('display', 'none');
		} else {
			domArticles.css('display', 'block');
			// process the list of articles to display
			// max 3 articles
			domArticles.children(articles.slice(0, 3).map(article => {
				let el;

				if (article.url == null || article.url.length === 0) {
					el = dom.parse(`<li><a class="${article.icon}" href="#">${article.title}</a></li>`);

					el.on('click', evt => {
						evt.stopPropagation();
						evt.preventDefault();
						events.emit('header.help.article.click', article);
						events.emit('sk-menu-close', elem);
					});
				} else {
					// perform string replacement on the URL
					const interpolatedUrl = Object.keys(urlTemplateVars)
						.reduce((url, key) => url.replace(`{${key}}`, urlTemplateVars[key]), article.url);

					el = dom.parse(`<li><a role="menuitem" aria-selected="false" class="${article.icon}" href="${interpolatedUrl}" target="_blank">${article.title}</a></li>`);
				}

				// bind common keydown handlers to menu item
				cmptMenuItem(elem, domButton, domArticles, el);

				return el;
			}));
		}

		// blocks
		var blocks = ctx.blocks;
		if (blocks == null || blocks.length === 0) {
			domBlocks.css('display', 'none');
		} else {
			domBlocks.css('display', 'block');
			// process the list of blocks to display
			domBlocks.children(blocks.map(block => {
				let el;

				if (block.url == null || block.url.length === 0) {
					el = dom.parse(`<li><a role="menuitem" aria-selected="false" class="${block.icon}" href="#">${block.title}</a></li>`);

					el.on('click', evt => {
						evt.stopPropagation();
						evt.preventDefault();
						events.emit('header.help.block.click', block);
						events.emit('sk-menu-close', elem);
					});
				} else {
					el = dom.parse(`<li><a role="menuitem" aria-selected="false" class="${block.icon}" href="${block.url}" target="_blank">${block.title}</a></li>`);
				}


				// bind common keydown handlers to menu item
				cmptMenuItem(elem, domButton, domBlocks, el);

				return el;
			}));
		}
	}

	/**
	 * Extract values for URL "template" string from IDM data. Use default values if the data is not provided.
	 * @param  {Object} [idmData] - IDM data object
	 * @param  {Object} {idmData.userContext} IDM user context object
	 * @param  {Object} {idmData.product} IDM product object
	 */
	function getUrlTemplateVars({ identities, userContext, product } = {}) {
		// these names must match the placeholders values in the URL, which we control
		const idTypes = product && product.AssociatedRoles.map(x => x.Type) || [];
		const idRecord = Array.isArray(identities) && identities.find(id => idTypes.includes(id.Type));
		const userKey = idRecord && idRecord.Id || '';
		const orgId = userContext && userContext.OrganizationId || '';

		return {userKey, orgId};
	}
};
