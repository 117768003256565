import * as events from './event-bus';
import * as env from './env';
import * as store from './store';

import {
	get
} from './idm-helpers';

// Checks to see whether a user has visibility to themselves or themselves and others
export function checkViewOthersPermission(idmData) {
    const idm = store.get('idm');
    let envName = env.environmentFromIdmGatewayUrl(idm.idGatewayUrl);
	if (idm.product === 'FCQA') { envName = 'qa'; }
    const envValues = env[envName];
    const { ecSand } = envValues;

    const idmAdministrator = idmData.roles.includes('IdmAdministrator');

    let employee = null;
    if (idmData.identities){
		employee = idmData.identities.find(x => x.Type === 'employee' || x.Type === 'EmployeeQA');
    }

    if (employee) {
        get(`${ecSand}/PermissionAPI/api/NumVisibleContextIds/${idmData.userContext.OrganizationId}/Employee?objectName=Employee&actionName=View`, function (res) {
            // if this response is greater than 1 the user can see others (in which case we'll show "Employee Directory" rather than "My Profile")
            const viewOthers = res > 1 ? true : false;
            events.emit('view-others-permissions-checked', viewOthers );
        }, [{key: 'fluser', value: JSON.stringify({'UserKey': employee.Id})}]);
    }else if (idmAdministrator) {
        events.emit('view-others-permissions-checked', true );
    } else {
        events.emit('view-others-permissions-checked', false );
    }
}

