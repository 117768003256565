(function (plugin) {
// ================= Plugin Name ================= //
//                                                 //
    var name = 'height';
//                                                 //
// =============================================== //
    window.ambient.registerPlugin(name, plugin);
})({
// ================= Plugin Code ================= //
//                                                 //
    getCurrentValue: function () {
        var w = window,
            d = document,
            b = d.body;

        return w.innerHeight || b.clientHeight;
    },
    getActiveClasses: function (winHeight, className, props) {
        var aboveMin, belowMax,
            min = props.min || 0,
            max = props.max || Infinity;

        aboveMin = winHeight >= min;
        belowMax = winHeight < max;

        if (aboveMin && belowMax) {
            return [className];
        } else {
            return [];
        }
    },
    watchEvent: 'resize'
//                                                 //
// =============================================== //
});
