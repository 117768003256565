import * as events from '../../util/event-bus';

/**
 * Container class
 * @class Sidekick.ActivityIndicator
 * @memberOf Sidekick
 * @param {EasyDom} elem - Container {@link EasyDom} element
 * @param {Object} attrs
 * @param {Object} ctx
 * @param {String} ctx.url - URL that clicking this should go to if passed in
 * @param {Function} i18n
 * @emits activity-indicator.clicked - Indicates the activity indicator has been clicked if URL not specified
 * @listens activity-indicator.visible - true/false - show hide the indicator
 */
export default function(elem, attrs, ctx, i18n) {

	/////
	// init styles
	//
	elem.addClass('sk-hidden');

	/////
	// dom elements we require access to
	//
	const domLink = elem.find('a')[0];

	/////
	// listen for events and take action
	//
	events.on('activity-indicator.visible', show => elem.toggleClass('sk-hidden', !show));

	/////
	//
	//
	ctx.url = ctx.url || '#';

	if (ctx.url === '#') {
		domLink.on('click', evt => {
			evt.stopPropagation();
			evt.preventDefault();
			events.emit('activity-indicator.clicked');
		});
	}
};
