export function deprecatedMsg(
	oldInterface, newInterface,
	tmpl = `DEPRECATED => ${oldInterface} is being removed! Use ${newInterface} instead.`
) {
	console && console.error && console.error(tmpl); // eslint-disable-line
};

export function removedMsg(
	oldInterface,
	tmpl = `REMOVED => ${oldInterface} is being removed! Do not use it!`
) {
	console && console.error && console.error(tmpl); // eslint-disable-line
};

export function deprecatedAttr({obj, attr, objLoc = '', newLoc = null} = {}, getterOrDefault) {
	const getterFunc = (
		typeof getterOrDefault === 'function'
			? getterOrDefault
			: () => getterOrDefault
	);

	// clear out the attr if it was previously defined
	if (obj.hasOwnProperty(attr)) { delete obj[attr]; }

	// define the getter function
	Object.defineProperty(obj, attr, {get() {
		const dot = (objLoc ? '.' : '');
		const oldLoc = `${objLoc}${dot}${attr}`;

		if (newLoc == null) {
			removedMsg(oldLoc);
		} else {
			deprecatedMsg(oldLoc, newLoc);
		}
		return getterFunc();
	}});
};
