import { glean } from './object-helpers';

/**
 * @module Sidekick.AssetLoader
 * @memberOf Sidekick
 */

/**
 * Returns the base path, respecting Storm's overridden value if it is set
 * @function baseAssetPath
 * @instance
 */
export function getBaseAssetPath() {
	const currentSidekickSrc = document.currentScript && document.currentScript.src ? document.currentScript.src : 'https://sidekick.uitools.frontlineeducation.com';
	const sidekickUrl = new URL(currentSidekickSrc);
	const defaultUrl = sidekickUrl.origin;
	return glean(window, (w) => w.ss.env.tools.sidekick, defaultUrl);
}

const baseAssetPath = getBaseAssetPath();

export function isUrlUiModule(url) {
	const urlPattern = /^https:\/\/(supersuitaws(.*?)\.flqa\.net|(app|zeit)\.frontlineeducation\.com)\//;
	return urlPattern.test(url);
}

/**
 * Loads a script
 * @function script
 * @instance
 * @param  {String} fileName
 */
export const script = loadScript;

/**
 * Loads a style sheet
 * @function stylesheet
 * @instance
 * @param  {String} fileName
 * @param  {Function} success
 * @param  {Function} failure
 */
export const stylesheet = loadStylesheet;

/**
 * Loads the sidekick print styles (hides sidekick for print)
 * @function printStyles
 * @instance
 */
export const printStyles = function() {
	const el = document.createElement('style');
	el.setAttribute('type', 'text/css');
	el.setAttribute('media', 'print');
	el.innerHTML = '.sidekick { display: none !important; } .sidekick-wrapped-application {	margin: 0 !important } ';
	document.head.appendChild(el);
};


/**
 * Loads a font
 * @function fontFace
 * @instance
 * @param  {String} fontName
 */
export const fontFace = loadFont;

/////
// helper functions
//

function loadStylesheet(fileName, success, basePath = baseAssetPath) {
	const fileLoc = `${basePath}/${fileName}`;
	const linkAttrs = {
		'rel': 'stylesheet',
		'media': 'screen',
		'charset': 'utf-8',
		'href': fileLoc,
	};
	const styleTestAttrs = {
		style: 'display: none;',
		'class': 'sidekick-stylesheet-test',
	};

	const linkEl = injectTag('link', linkAttrs, document.head);
	// watch hidden element for style update from sidekick.css
	const styleTestEl = injectTag('div', styleTestAttrs, document.body);

	const checkStyles = setInterval( function() {
		try {
			if (window.getComputedStyle(styleTestEl).visibility === 'hidden') {
				clearInterval(checkStyles);
				success(linkEl);
				document.body.removeChild(styleTestEl);
			}
		} catch(e) {}
	}, 50);
}

function loadScript(fileName, basePath = baseAssetPath) {
	const fileLoc = `${basePath}/${fileName}`;
	const attrs = {'type':'text/javascript', 'charset':'utf-8', 'src':fileLoc};
	return injectTag('script', attrs, document.body);
}

function injectTag(tagName, attrs, target) {
	const el = document.createElement(tagName);
	Object.keys(attrs).sort().forEach(attr => el.setAttribute(attr, attrs[attr]));
	target.appendChild(el);
	return el;
}

const fontCode = {
	'fl-icons': fontFaceCss('FL-Icons', 'FL-Icons', 'FL-Icons', 'normal', 'normal'),
	'open-sans': (
		// Open Sans: regular
		fontFaceCss('Open Sans', 'opensans-regular-webfont', 'open_sansregular', 400, 'normal') +
		// Open Sans: semi-bold
		fontFaceCss('Open Sans', 'opensans-semibold-webfont', 'open_sanssemibold', 600, 'normal') +
		// Open Sans: bold
		fontFaceCss('Open Sans', 'opensans-bold-webfont', 'open_sansbold', 700, 'normal')
	)
};
function loadFont(fontName) {
	const el = document.createElement('style');
	el.setAttribute('type', 'text/css');
	el.innerHTML = fontCode[fontName.toLowerCase()];
	document.head.appendChild(el);
}

function fontFaceCss(fontName, fileName, svgType, fontWeight, fontStyle) {
	return (`
		@font-face {
			font-family: '${fontName}';
			src: url('${baseAssetPath}/font/${fileName}.eot?#iefix') format('embedded-opentype'),
				url('${baseAssetPath}/font/${fileName}.woff') format('woff'),
				url('${baseAssetPath}/font/${fileName}.ttf') format('truetype'),
				url('${baseAssetPath}/font/${fileName}.svg#${svgType}') format('svg');
			font-weight: ${fontWeight};
			font-style: ${fontStyle};
		}
	`).replace(/[\t\n]+/g, ' ').replace(/^\s+|\s+$/g, '');
}
