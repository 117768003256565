import _debounce from 'debounce';
import * as dom from '../../util/easy-dom';
import * as events from '../../util/event-bus';
import {cmptInputClear, queryStringObject} from '../../util/cmpt-common-features';

export default function (elem, attrs, ctx, i18n) {

	var {access, productId, orgId} = ctx;

	const qsData = queryStringObject();
	if (qsData.orgId != null) { orgId = qsData.orgId; }
	if (qsData.productId != null) { productId = qsData.productId; }
	const productIdUpper = productId.toUpperCase();

	/////
	// get handle on elements we need
	//
	const domSearchContainer = elem.find('.search-container')[0];
	const domSearchInput = elem.find('.search')[0];
	const domSearchClearBtn = elem.find('.ss-ctrl-clear')[0];
	const domOrgList = elem.find('.sk--org-app-list')[0];
	const domAppFilter = elem.find('.sk--filtered-app-name')[0];
	const domOrgLoadAllBtn = elem.find('.load-all-btn')[0];
	var orgListAccessRecords = [];
	var filteredByTitle = '';
	const limit = 10; // minimum value of rows to be present before showing search box

	if (orgId != null) {
		// if the orgId is provided, limit using it
		orgListAccessRecords = access.filter(a => a.OrganizationId === orgId);
	}

	// if we still don't have any records
	if (orgListAccessRecords.length === 0) {
		// get access records based on the current app
		orgListAccessRecords = access.filter(a => a.ProductId.toUpperCase() === productIdUpper);
		if (orgListAccessRecords.length) {
			filteredByTitle = orgListAccessRecords[0].ProductName;
		}
	}

	// if we still don't have any records
	if (orgListAccessRecords.length === 0) {
		// show all orgs we have access to
		orgListAccessRecords = access;
	}

	// de-duplicate the records to just unique orgs
	orgListAccessRecords = deDupOrgAccess(orgListAccessRecords);

	// if we are left with just 1, pick it
	if (orgListAccessRecords.length === 1) {
		dom.rAF(function () {
			events.emit('_pick-org.org-id-selected', orgListAccessRecords[0].OrganizationId);
		});
		return;
	}

	if (filteredByTitle.length) {
		domAppFilter._.innerHTML = filteredByTitle;
		domAppFilter.removeClass('sk-hidden');
	}
	elem.addClass(orgListAccessRecords.length > limit ? 'search-visible' : '');

	/**
	 * Filters orgs
	 */
	function searchOrgs(org) {
		const filter = domSearchInput._.value;
		// no search filter
		if (!filter.length) { return true; }
		// search filter
		return org.OrganizationName.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
	}

	/////
	// listen and react to events
	//
	const debouncedFn = _debounce(() => render(), 200);
	cmptInputClear(domSearchInput, domSearchClearBtn);
	domSearchInput.on('input', debouncedFn);
	domSearchClearBtn.on('click', () => render());
	domOrgLoadAllBtn.on('click', () => render(-1));
	render();

	/**
	 * Processes the org data to render the list
	 * @param {number} displayLimit - number of rows to be rendered in DOM
	 */
	function render(displayLimit = 20) {
		domSearchContainer.css('display', orgListAccessRecords.length > limit ? 'block' : 'none');

		orgListAccessRecords.sort(function (a, b) {
			const aName = a.OrganizationName.toLowerCase();
			const bName = b.OrganizationName.toLowerCase();
			if (aName > bName) { return 1; }
			if (aName < bName) { return -1; }
			return 0;
		});

		const searchResultsOrgList = orgListAccessRecords.filter(searchOrgs);
		domOrgList.children(searchResultsOrgList.slice(0, displayLimit < 0 ? searchResultsOrgList.length : displayLimit).map(function (a) {
			const item = dom.parse(`
				<li>
					<a href="#" data-org-id="${a.OrganizationId}">
						${a.OrganizationName}
					</a>
				</li>
			`);

			item.on('click', function (evt) {
				evt.preventDefault();
				events.emit('_pick-org.org-id-selected', a.OrganizationId);
			});

			return item;
		}));
		if (searchResultsOrgList.length <= displayLimit || displayLimit < 0) {
			domOrgLoadAllBtn.css('display', 'none');
		} else {
			domOrgLoadAllBtn.css('display', 'inline-block');
		}
	}

	/////
	// helper functions
	//
	function deDupOrgAccess(accessRecords) {
		return accessRecords.filter(function (v, i, a) {
			const idx = a.findIndex(av => av.OrganizationId === v.OrganizationId);
			return idx === i;
		});
	}
};
