import {cmptMenu, cmptMenuItem, cmptDynamicWidth} from '../../util/cmpt-common-features';
import * as dom from '../../util/easy-dom';
import * as store from '../../util/store';
import * as events from '../../util/event-bus';

/**
 * User info component.
 * @class Sidekick.UserInfo
 * @memberOf Sidekick
 * @param {EasyDom} elem - Container {@link EasyDom} element
 * @param {Object} attrs
 * @param {Object} ctx - user data object
 * @param {Boolean} [ctx.firstName] - First Name to show
 * @param {Boolean} [ctx.lastName] - Last Name to show
 * @param {Boolean} [ctx.showAcctSettings=true] - Show/hide the Account Settings button
 * @param {Boolean} [ctx.showSignOut=true] - Show/hide the Signout button
 * @param {Function} i18n - translation function
 * @emits header.user-info.role - Role to switch to
 * @emits header.user-info.account-settings
 * @emits session.logout
 * @listens roles - Accepts a collection of roles
 * @example
 * currentRole: ID of the current role that is in the roles array
 * roles: List of roles pertaining to the app
 * {
 	currentRole: 1,
 	roles: [{
		'id': 1,
		'name': 'Admin'
	}]
}
 */
export default function(elem, attrs, ctx, i18n) {
	// button display controls
	let showAcctSettings = ctx.showAcctSettings !== false;
	let showSignOut = ctx.showSignOut !== false;

	/////
	// find dom elements that we care about
	//
	const [domButton] = elem.find('.sk--control');
	const [domUserName] = domButton.find('.sk--user-name');
	const [domRoleName] = domButton.find('.sk--current-role');
	const [domMenu] = elem.find('.sk--menu');
	const [domSettings] = elem.find('.sk--settings');
	const [domLogout] = elem.find('.sk--logout');
	const [domRoles] = elem.find('.sk--roles');
	const [domBlocks] = elem.find('.sk--blocks');
	const [domFilter] = elem.find('.sk--filter');
	const [domFilterInput] = elem.find('.sk--roles-filter');
	const [domFlUserAccountHeader] = elem.find('.sk--fl-account-header');

	// Passport specific elements
	const [domFrontlineHome] = elem.find('.sk--frontline-home');
	const [domEndImpersonation] = elem.find('.sk--end-impersonation');

	/////
	// handle iniital state and data changes
	//

	store.onValue('user-data', function(user) {
		ctx.firstName = user.firstName;
		ctx.lastName = user.lastName;
		domButton.attr('aria-label', `${ctx.firstName} ${ctx.lastName}`);
		domUserName._.innerHTML =`${ctx.firstName} ${ctx.lastName}`;
		domUserName.attr('title', `${ctx.firstName} ${ctx.lastName}`);
	});
	store.onValue('roles', updateRoleData);
	store.onValueOnce('idm-data', function ({user} = {}) {
		// if this use is an SSO user (not a frontline account) we need to hide
		// the settings link that normally takes them to the IDM settings page
		if (!user.isFrontlineUser) {
			domSettings.parent().css('display', 'none');
			domFlUserAccountHeader.css('display', 'none');
		}
	});

	store.onValueOnce('passport-data', function ({ authProfile, impersonator }) {

		domFrontlineHome.parent().css('display', 'list-item');
			domFrontlineHome.on('click', function(evt) {
				evt.stopPropagation();
				evt.preventDefault();
				events.emit('header.user-info.frontline-home');
				events.emit('sk-menu-close', elem);
			});

		if (impersonator) {
			domEndImpersonation.parent().css('display', 'list-item');
			domEndImpersonation.on('click', function(evt) {
				evt.stopPropagation();
				evt.preventDefault();
				events.emit('header.user-info.end-impersonation');
				events.emit('sk-menu-close', elem);
			});
		}
	});

	/////
	// listen for events and take action
	//
	cmptDynamicWidth('user-info', elem, domUserName._.innerText, 45);
	cmptMenu(elem, domButton, domMenu);
	events.on('sk-menu-open', function(el) {
		if (el !== elem) { return; }
		domUserName.removeClass('fa-angle-down-after').addClass('fa-angle-up-after');
	});
	events.on('sk-menu-close', function(el) {
		if (el !== elem) { return; }
		domUserName.addClass('fa-angle-down-after').removeClass('fa-angle-up-after');
		clearFilter();
	});

	domFilterInput.on('keyup', function(evt) {
		evt.stopPropagation();
		evt.preventDefault();
		[ ...domRoles._.childNodes ].forEach(item => {
			if (!item.innerText.toLowerCase().includes(evt.currentTarget.value.toLowerCase())) {
				item.classList.add('hide');
			} else {
				item.classList.remove('hide');
			}
			return item;
		});
	});

	// hide blocks container if neither button is present
	if (showAcctSettings === false && showSignOut === false) {
		domBlocks.css('display', 'none');
	} else {
		if (showAcctSettings) {
			domSettings.on('click', function(evt) {
				evt.stopPropagation();
				evt.preventDefault();
				events.emit('header.user-info.account-settings');
				events.emit('sk-menu-close', elem);
			});

			// bind common keydown handlers to menu item
			cmptMenuItem(elem, domButton, domBlocks, dom.element(domSettings._.parentNode));
		} else {
			domSettings.parent().css('display', 'none');
		}

		if (showSignOut) {
			domLogout.on('click', function(evt) {
				evt.stopPropagation();
				evt.preventDefault();
				// This logout event is for consuming application
				events.emit('session.logout');
				events.emit('sk-menu-close', elem);
				// This logout event is used by sidekick internal only
				if (store.get('passport-data')) {
					events.emit('_sk-logout');
				}
			});

			// bind common keydown handlers to menu item
			cmptMenuItem(elem, domButton, domBlocks, dom.element(domLogout._.parentNode));
		} else {
			domLogout.parent().css('display', 'none');
		}
	}

	/////
	// helper functions
	//
	/**
	 * Updates the display with the new data being passed in.
	 * @param  {Object} data - New roles
	 */

	function clearFilter() {
		domFilterInput._.value= ''; // clear filter
		[ ...domRoles._.childNodes ].forEach(item => {
			item.classList.remove('hide');
			return item;
		});
	}

	function updateRoleData(data) {
		// validate data structure
		if (data.currentRole == null) { return; }
		if (data.roles == null) { return; }
		if (data.roles.length === 0) { return; }
		if (data.roles.filter(r => r.id == null || r.name == null).length) { return; }

		const { currentRole, roles } = data;
		const currentRoleName = roles.find(r => r.id === currentRole).name;

		domRoleName._.innerHTML = currentRoleName || '';

		if (currentRoleName != null) {
			domButton.attr('aria-label', `${ctx.firstName} ${ctx.lastName} - ${currentRoleName}`);
		}

		if (roles == null || roles.length === 0) {
			domRoles.css('display', 'none');
			domRoles.children();
			domRoleName.css('display', 'none');
			domRoleName.children();
		} else if (roles.length === 1) {
			domRoleName.css('display', 'block');
			domRoles.css('display', 'none');
			domRoles.children();
		} else {
			domRoles.css('display', 'block');
			domRoleName.css('display', 'block');
			// show roles filter check
			if (roles.length > 20) {
				domFilter.css('display', 'block');
			}
			// process the list of roles to display
			domRoles.children(roles.map(role => {
				const isActive = role.id === currentRole;

				const ariaCurrent = isActive.toString();
				const linkClasses = isActive ? 'fa-li-checkmark-after active' : '';

				const el = dom.parse(`
					<li>
						<a role="menuitem" aria-current="${ariaCurrent}" aria-selected="false" class="active-state-link ${linkClasses}" href="#">${role.name}</a>
					</li>
				`);

				if (role.id !== currentRole) {
					el.on('click', evt => {
						evt.stopPropagation();
						evt.preventDefault();
						events.emit('header.user-info.role', role);
						events.emit('sk-menu-close', elem);
					});
				}

				// bind common keydown handlers to menu item
				cmptMenuItem(elem, domButton, domRoles, el);

				return el;
			}));
		}
	}
};
