import * as dom from '../../util/easy-dom';
import {cmptMenu, cmptMenuItem} from '../../util/cmpt-common-features';
import * as events from '../../util/event-bus';

/**
 * Displays allowed organizations
 * @class Sidekick.ApplicationSwitcher
 * @memberOf Sidekick
 * @param {EasyDom} elem - Container {@link EasyDom} element
 * @param {Object} attrs
 * @param {Object} ctx - app switcher data object
 * @param {Function} i18n - translation function
 * @emits header.app-switcher.change - Passes selected product id
 */
export default function(elem, attrs, ctx, i18n) {
	/////
	// find dom elements that we care about
	//
	const [domBtn] = elem.find('.app-title');
	const [domBtnContent] = domBtn.find('.sk--content');
	const [domAppCont] = elem.find('.sk--menu');
	const [domAppList] = elem.find('.sk--menulist');

	/////
	// listen for events and take action
	//
	cmptMenu(elem, domBtn, domAppCont);

	/**
	 * Processes the access data to populate the list
	 * @param  {Object} app
	 */
	const {currentApp, apps} = ctx;
	const currApp = apps.find(a => a.id === currentApp);
	const appName = currApp == null ? '' : currApp.name;

	domBtn.attr('aria-label', `${appName} - ${i18n('Switch Apps')}`);
	domBtnContent.attr('title', appName);
	domBtnContent._.innerHTML = appName;

	// Disable skip link when impersonating
	events.on('_header.impersonation', isImpersonating => {
		const [domSkipLink] = elem.find('.sk--skip-nav-link');
		if (domSkipLink == null) { return; }
		domSkipLink.css('display', (isImpersonating ? 'none' : null));
	}, { greedy: true });

	// hide menu on only 1 app
	if (apps.length < 2) {
		elem.addClass('sk-no-click');
		domBtn.attr('tabindex', '-1');
		domBtn.attr('disabled', 'disabled');
		domBtn.attr('aria-disabled', 'true');
		domBtnContent.removeClass('fa-angle-down-after');
	} else {
		elem.removeClass('sk-no-click');
		domBtn.attr('tabindex', '0');
		domBtn.attr('disabled', null);
		domBtn.attr('aria-disabled', 'false');
		domBtnContent.addClass('fa-angle-down-after');

		// Sort by name
		apps.sort((a, b) => a.name.localeCompare(b.name));

		domAppList.children(apps.map(app => {
			const isActive = app.id === currentApp;
			const hasLegacyName = app.legacyName != null && app.legacyName.length;

			const ariaCurrent = isActive.toString();
			const liClassName = isActive ? 'active' : '';
			const nameClassName = isActive ? 'fa-li-checkmark-after' : '';

			const href = app.url || '#';
			const target = app.url && app.target ? ` target="${app.target}"` : '';

			const el = dom.parse(`
				<li>
					<a role="menuitem" aria-current="${ariaCurrent}" aria-selected="false" class="active-state-link ${liClassName}" href="${href}"${target}><span class="sk--name ${nameClassName}">${app.name}</span></a>
				</li>
			`);

			if (isActive) { el.addClass('active'); }
			if (hasLegacyName) { el.addClass('sk--has-legacy'); }

			el.on('click', evt => {
				if (!app.url) {
					evt.stopPropagation();
					evt.preventDefault();
				}
				// Making Event Emit in all the conditions as a part of MORPH-6346
				events.emit('header.app-switcher.change', app);
				events.emit('sk-menu-close', elem);
			});

			// bind common keydown handlers to menu item
			cmptMenuItem(elem, domBtn, domAppList, el);

			return el;
		}));
	}

};
