import * as store from './store';

const defaults = {
	type: 'session',
	expires: 30 // in minutes
};
const inverseType = {session: 'local', local: 'session'};
const sStore = window.sessionStorage;
const lStore = window.localStorage;
const ns = 'SK_';

export const set = function (key, data, config) {
	const cfg = Object.assign({}, defaults, config, {data});
	clearUnusedKeys(`${cfg.type}Storage`, key);
	if (typeof cfg.expires === 'number' && isFinite(cfg.expires)) {
		const minToMs = 60 * 1000;
		cfg.expires = Date.now() + (cfg.expires * minToMs);
	} else {
		cfg.expires = null;
	}
	try {
		window[`${cfg.type}Storage`].setItem(`${ns}${key}`, JSON.stringify(cfg));
		window[`${inverseType[cfg.type]}Storage`].removeItem(`${ns}${key}`);
	} catch(e) {
		/* eslint-disable no-console */
		console.error('Sidekick error: ', e);
	}
};

export const get = function (key) {
	const raw = sStore.getItem(`${ns}${key}`) || lStore.getItem(`${ns}${key}`) || null;
	if (raw == null) { return null; }
	const json = JSON.parse(raw);

	// if the data has expired, return null
	if (json.expires != null && json.expires < Date.now()) { return null; }
	return json.data;
};

// cleanup the expired persistence values when we first load
store.onValueOnce('sk-config', function (cfg) {
	const {cleanCache} = cfg;

	// clean out anything that is expired
	[lStore, sStore].forEach(function (storage) {
		// spin all the items in local/session storage
		var i = storage.length;
		for (; i-- ;) {
			// get the key to inspect
			const key = storage.key(i);
			// if it's not a SideKick value, don't mess with it
			if (!key.startsWith(ns)) { continue; }
			// can we get data for this key?
			const data = get(key.slice(ns.length));
			// if the data is null or we are in a clearing cache state, remove it
			if (data == null || cleanCache) { storage.removeItem(key); }
		}
	});
});

/**
 * This method clears all the unused Sidekick data, that starts with {@link ns} @param {String} key[0] in the storage.
 * @param {String} storageType type of storage, ex: localStorage, sessionStorage
 * @param {String} key the current key which is to be set. It contains the flId and sessionId at index [1] & [2] when split by '-'
 */
function clearUnusedKeys(storageType, key) {
	const keyData = key.split('-');
	if(keyData.length > 2) {
		const existingKeys = Object.keys(window[storageType]).filter(item => item.startsWith(`${ns}${keyData[0]}`));
		const unusedKeys = existingKeys.filter(item => !item.endsWith(`-${keyData[1]}-${keyData[2]}`));
		if(unusedKeys.length > 0) {
			for(const unusedKey of unusedKeys) {
				window[storageType].removeItem(unusedKey);
			}
		}
	}
}
