import _debounce from 'debounce';
import * as dom from '../../util/easy-dom';
import {cmptMenu, cmptMenuItem, cmptInputClear, cmptDynamicWidth} from '../../util/cmpt-common-features';
import * as store from '../../util/store';
import * as events from '../../util/event-bus';


/**
 * Displays allowed organizations
 * @class Sidekick.OrganizationSwitcher
 * @memberOf Sidekick
 * @param {EasyDom} elem - Container {@link EasyDom} element
 * @param {Object} attrs
 * @param {Object} ctx - org switcher context object
 * @param {Function} i18n - translation function
 * @emits header.org-switcher.change - Passes selected organization
 */
export default function(elem, attrs, ctx, i18n) {
	const limit = 10; // minimum value of rows to be present before showing search box
	const listEmpty = dom.parse('<li class="sk-list-empty">No Organizations Found</li>');

	/////
	// find dom elements that we care about
	//
	const [domBtn] = elem.find('.control');
	const [domBtnContent] = domBtn.find('.content');
	const [domOrgCont] = elem.find('.menu');
	const [domOrgList] = elem.find('.menulist');
	const [domSearchCont] = elem.find('.search');
	const [domSearchText] = elem.find('.search-org');
	const [domClearBtn] = elem.find('.ss-ctrl-clear');
	const [domOrgLoadAllBtn] = elem.find('.load-all-btn');

	/////
	// wait to handle this initial state until the current org is specified
	//
	store.onValue('current-org', function (org) {
		ctx.orgs
			.filter(o => o.id.toString() === org.id.toString())
			.filter(o => o.name.startsWith('IDM ADMIN:'))
			.forEach(o => o.name = org.name);

		render();
	});
	render();
	const debouncedFn = _debounce(() => render(), 200);

	/////
	// listen for events and take action
	//
	cmptMenu(elem, domBtn, domOrgCont);
	cmptInputClear(domSearchText, domClearBtn);
	domSearchText.on('input', debouncedFn);
	domClearBtn.on('click', () => render());
	domOrgLoadAllBtn.on('click', () => render(-1));
	events.on('sk-menu-close', () => {
		if (domOrgLoadAllBtn.css('display') === 'none') {
			render();
		}
	});

	/////
	// helper functions
	//

	/**
	 * Filters orgs
	 */
	function searchOrgs(org) {
		const filter = domSearchText._.value;
		// no search filter
		if (!filter.length) { return true; }
		// search filter
		return org.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
	}

	/**
	 * Processes the access data to populate the list
	 * @param {number} displayLimit - number of rows to be rendered in DOM
	 */
	function render(displayLimit = 20) {
		const {orgs, currentOrg} = ctx;

		// no access, don't show, we are done
		if (currentOrg == null || orgs.length === 0) {
			elem.css('display', 'none');
			return;
		}

		elem.css('display', 'inline-block');

		// render out our current org name in the header
		const orgName = orgs.find(o => o.id === currentOrg).name;
		domBtn.attr('aria-label', `${orgName} - ${i18n('Switch Organizations')}`);
		domBtnContent._.innerHTML = orgName;
		domBtnContent.attr('title', orgName);
		cmptDynamicWidth('org-switcher', elem, orgName, 30);

		if (orgs.length === 1) {
			elem.addClass('sk-no-click');
			domBtn.attr('tabindex', '-1');
			domBtn.attr('disabled', 'disabled');
			domBtn.attr('aria-disabled', 'true');
			domBtnContent.removeClass('fa-angle-down-after');
			domOrgCont.css('display', 'none');
			// no need to continue, we only have 1 org. No picker is needed.
			return;
		} else {
			elem.removeClass('sk-no-click');
			domBtn.attr('tabindex', '0');
			domBtn.attr('disabled', null);
			domBtn.attr('aria-disabled', 'false');
			domBtnContent.addClass('fa-angle-down-after');
			domSearchCont.css('display', orgs.length > limit ? 'block' : 'none');
		}

		// Sort by name
		orgs.sort((a, b) => {
			return a.name.localeCompare(b.name);
		});

		const searchResultsOrgList = orgs.filter(searchOrgs);
		if (searchResultsOrgList.length > 0) {
			domOrgList.children(searchResultsOrgList.slice(0, displayLimit < 0 ? searchResultsOrgList.length : displayLimit).map(org => {
				const isActive = org.id === currentOrg;

				const ariaCurrent = isActive.toString();
				const liClassName = isActive ? 'active' : '';
				const linkClassName = isActive ? 'fa-li-checkmark-after active' : '';

				const href = org.url || '#';
				const target = (org.url && org.target ? ` target="${org.target}"` : '');

				const el = dom.parse(`
					<li class="${liClassName}">
						<a role="menuitem" aria-current="${ariaCurrent}" aria-selected="false" class="active-state-link ${linkClassName}" href="${href}"${target}>${org.name}</a>
					</li>
				`);

				el.on('click', evt => {
					if (!org.url) {
						evt.stopPropagation();
						evt.preventDefault();
						events.emit('header.org-switcher.change', org);
					}
					events.emit('sk-menu-close', elem);
				});

				// bind common keydown handlers to menu item
				cmptMenuItem(elem, domBtn, domOrgList, el);

				return el;
			}));
		} else {
			domOrgList.children(listEmpty);
		}
		if (searchResultsOrgList.length <= displayLimit || displayLimit < 0) {
			domOrgLoadAllBtn.css('display', 'none');
		} else {
			domOrgLoadAllBtn.css('display', 'block');
		}
	}
};
