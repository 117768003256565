import * as dom from './easy-dom';
import * as events from './event-bus';
import * as store from './store';
import * as keyPress from './key-press-helpers';

/**
 * @module Sidekick.ComponentCommon
 * @memberOf Sidekick
 */

/**
 * Sets up common <code>on</code> handlers for a menu item
 * @function cmptMenuItem
 * @instance
 * @param  {Element} domCmpt
 * @param  {Element} domHandle
 * @param  {Element} mItem
 */
export function cmptMenuItem(domCmpt, domHandle, domMenuList, domMenuItem) {
	domMenuItem.find('[role=menuitem]').forEach(domMenuItemLink => {
		domMenuItemLink.on('focus', evt => {
			domMenuItemLink.attr('aria-selected', true);
		});

		domMenuItemLink.on('blur', evt => {
			domMenuItemLink.attr('aria-selected', false);
		});
	});

	domMenuItem.on('keydown', evt => {
		let prevDef = false;

		switch(true) {
			case keyPress.isTab(evt):
				break;
			case keyPress.isLeft(evt):
			case keyPress.isRight(evt):
				break;
			case keyPress.isUp(evt):
			case keyPress.isDown(evt):
				const menuItemElems = Array.prototype.slice.call(domMenuList._.children);
				const menuItemIdx = menuItemElems.indexOf(domMenuItem._);
				if (menuItemIdx === (keyPress.isUp(evt) ? 0 : menuItemElems.length - 1)) {
					prevDef = true;
					break;
				}
				const nextIdx = menuItemIdx + (keyPress.isUp(evt) ? -1 : 1);
				const nextElem = menuItemElems[nextIdx].querySelector('[role=menuitem]');
				nextElem.focus();
				evt.stopPropagation(); // override up/down handling at the menu level
				prevDef = true;
				break;
		}
		if (prevDef) {
			evt.preventDefault();
		}
	});
}

/**
 * Sets up common <code>on</code> handlers for a menu
 * @function cmptMenu
 * @instance
 * @param  {Element} domCmpt
 * @param  {Element} domHandle
 * @param  {Element} domMenu
 */
export function cmptMenu(domCmpt, domHandle, domMenu) {
	function onClick(evt) {
		const isOpen = domHandle.hasClass('ss-menu-open');
		events.emit(`sk-menu-${isOpen ? 'close' : 'open'}`, domCmpt, evt);
	}

	domCmpt.on('click', function (evt) {
		if (!domCmpt.hasClass('sk-no-click')) { evt.stopPropagation(); }
	});
	domHandle.on('click', onClick);
	domHandle.on('keydown', (evt) => {
		let prevDef = false;
		switch (true) {
			case keyPress.isEnter(evt):
			case keyPress.isSpace(evt):
			case keyPress.isDown(evt):
			case keyPress.isUp(evt):
				onClick(evt);
				prevDef = true;
				break;
		}
		if (prevDef) {
			evt.preventDefault();
		}
	});
	domMenu.attr('tabindex', '-1');
	domMenu.on('keydown', (evt) => {
		let prevDef = false;
		switch (true) {
			case keyPress.isDown(evt):
			case keyPress.isUp(evt):
				domMenu
					.find('[role=menuitem]')
					.forEach((mItem, idx, menuItems) => {
						const focusIdx = keyPress.isUp(evt) ? menuItems.length - 1 : 0;
						const focusOrBlur = idx === focusIdx ? 'focus' : 'blur';
						mItem._[focusOrBlur]();
					});
				break;
			case keyPress.isEscape(evt):
				events.emit('sk-menu-close', domCmpt);
				domHandle._.focus();
				prevDef = true;
				break;
		}
		if (prevDef) {
			evt.preventDefault();
		}
	});
	dom.element(window).on('blur', function () {
		if (document.activeElement != null && document.activeElement.tagName === 'IFRAME') {
			events.emit('sk-menu-close', domCmpt);
		}
	});
	dom.element(document).on('click', () => events.emit('sk-menu-close', domCmpt));

	// handle open events
	events.on('sk-menu-open', function (cmpt, evt) {
		if (cmpt !== domCmpt) {
			// if this was an open event for another menu, close me
			events.emit('sk-menu-close', domCmpt);
		} else {
			// this was an open event for me, open me
			domHandle.addClass('ss-menu-open');
			domHandle.attr('aria-pressed', 'true');
			domMenu.addClass('ss-menu-open');
			domMenu.attr('aria-hidden', 'false');

			if (keyPress.isDown(evt) || keyPress.isUp(evt)) {
				// focus the appropriate menu item (the first item by default, or the
				// last item if the 'up' keyCode was emitted)
				domMenu
					.find('[role=menuitem]')
					.forEach((mItem, idx, menuItems) => {
						const focusIdx = keyPress.isUp(evt) ? menuItems.length - 1 : 0;
						const willFocus = idx === focusIdx;
						mItem._[willFocus ? 'focus' : 'blur']();
					});
				return;
			}

			domMenu._.focus();
		}
	});

	// handle close events
	events.on('sk-menu-close', function (cmpt, external = true) {
		// if this close event was for another menu, ignore
		if (cmpt !== domCmpt) { return; }
		// close the menu
		domHandle.removeClass('ss-menu-open');
		domHandle.attr('aria-pressed', 'false');
		domMenu.removeClass('ss-menu-open');
		domMenu.attr('aria-hidden', 'true');

		const menuItems = domMenu.find('[role=menuitem]');
		menuItems.map(mItem => mItem.attr('aria-selected', false));
	});
}

/**
 * handle the clear button functionality for an sk-input element
 * @function cmptInputClear
 * @instance
 * @param {Element} domInput
 * @param {Element} domClearBtn
 * @param {Boolean} clearOnEscape - Adds an event handler to run when you press escape
 */
export function cmptInputClear(domInput, domClearBtn, clearOnEscape) {
	// toggle sk-hidden based on the content
	const toggleClear = () => {
		domClearBtn.toggleClass('sk-hidden', !domInput._.value.length);
	};

	domInput.on('input', toggleClear);
	domClearBtn.on('click', clear);
	if (clearOnEscape) {
		domInput.on('keydown', clearOnEscapeCall);
	}

	toggleClear();

	function clear() {
		domInput._.value = '';
		domInput._.focus();
		toggleClear();
	}

	function clearOnEscapeCall(evt) {
		// bail out if this was not the ESCAPE key
		if (!keyPress.isEscape(evt)) { return; }

		clear();
	}
}

export const queryStringObject = function () {
	const raw = window.location.search;
	if (raw.length === 0) { return {}; }
	const rawArr = raw.slice(1).split('&');
	return rawArr.reduce(function (o, qs) {
		const [key, value] = qs.split('=');
		o[decodeURIComponent(key)] = decodeURIComponent(value);
		return o;
	}, {});
};

///////
// calculating and distribute the top bar extra width
// to dynamic width elements
//
var activityIndicatorWidth = 0;
var impersonationMode = false;
const staticElems = {};
const dynamicElems = {};
const staticWidthElemNames = ['app-switcher', 'search', 'help', 'notifications'];
const impStaticWidthElemNames = ['app-switcher', 'search'];
const dynamicWidthElemNames = ['user-info', 'org-switcher'];
const impDynamicWidthElemNames = ['impersonated-user', 'org-switcher'];

/**
 * cmptDynamicWidth - registers a header component that should be dynamic
 * width and occupy as much space (max-width) as possible
 *
 * @param {EasyDomElement} elem - the element that should be dynamic
 * @param {String} text - the dynamic text content to use for measurements
 * @param {Number} adjustment - the px amount to add to measurement to compensate for padding
 */
export const cmptDynamicWidth = function (name, elem, text, adjustment = 0, font = 'normal 15px') {
	const firstRegistration = dynamicElems[name] == null;
	dynamicElems[name] = {elem, text, adjustment, font};

	// if this elem is the user-info component and this is the first
	// time we see it being registered. start watching for role data
	if (name === 'user-info' && firstRegistration) { watchForRoles(); }

	// update our size calculations
	adjustDynamicWidthElements();
};

// re-calculate the measurements because adjustments have been made
function adjustDynamicWidthElements() {
	// decide what elements could be on the screen based on if we are impersonating
	const _staticWidthElemNames = (impersonationMode ? impStaticWidthElemNames : staticWidthElemNames);
	const _dynamicWidthElemNames = (impersonationMode ? impDynamicWidthElemNames : dynamicWidthElemNames);

	// calculate the total width of all static elements on the screen
	// give the header an extra 43 pixels for impersonation mode to accommodate the X
	const _totalStaticWidth = _staticWidthElemNames.reduce(function (total, elName) {
		const elWidth = staticElems[elName] && parseFloat(staticElems[elName].css('width'));
		return total + (elWidth || 0);
	}, (impersonationMode ? 48 : 5));

	// figure out what dyamic elements are on screen currently
	const _dynamicWidthElems = _dynamicWidthElemNames
		.filter(elName => dynamicElems[elName] != null)
		.map(elName => dynamicElems[elName])
		.map(e => Object.assign({}, e, {width: measureText(e.text, e.font)}));

	// calculate the total width of all dymanic elements based on their text width
	const _totalDynamicWidth = _dynamicWidthElems.reduce((t, e) => t + (e.width + e.adjustment), 0);

	// apply the corrent percent width calculation for each dynamic element
	_dynamicWidthElems.forEach(function (e) {
		const _staticWidth = _totalStaticWidth + activityIndicatorWidth;
		const widthPercent = Math.floor(((e.width + e.adjustment)/_totalDynamicWidth) * 100);
		const itemPxReductionAmt = _staticWidth * (widthPercent / 100);
		e.elem.css('max-width', `calc(${widthPercent}% - ${itemPxReductionAmt}px)`);
	});
}

const drawCtx = document.createElement('canvas').getContext('2d');
// measure how many px this text will take up
function measureText(text, font = 'normal 15px') {
	const fontFamily = `'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif`;
	drawCtx.font = `${font} ${fontFamily}`;
	return drawCtx.measureText(text).width;
}

// if we switch to or from impersonation mode, we should recalculate header space
events.on('_header.impersonation', function (impMode) {
	impersonationMode = impMode;
	adjustDynamicWidthElements();
});

// the activity-indicator doesn't initially take up space, but it requires
// space when it is shown. hook into the visible events and re-adjust if
// if shows up on the scene.
events.on(`_activity-indicator-initialized`, function (elem) {
	events.on(`activity-indicator.visible`, function (visible) {
		if (visible) {
			activityIndicatorWidth = parseFloat(elem.css('width')) + parseFloat(elem.css('margin-right'));
		} else {
			activityIndicatorWidth = 0;
		}
		adjustDynamicWidthElements();
	}, true);
}, true);

// if roles are filled, we need to make sure the role name is not longer
// than the user's name, if it is, we need to use it for calculations instead
function watchForRoles() {
	store.onValue('roles', function (data) {
		const roleName = data.roles.find(r => r.id === data.currentRole).name;
		const userInfo = dynamicElems['user-info'];
		const userNameWidth = measureText(userInfo.text, userInfo.font);
		const roleFont = '600 13px';
		const roleWidth = measureText(roleName, roleFont);

		if (roleWidth > userNameWidth) {
			userInfo.text = roleName;
			userInfo.font = roleFont;
		}

		adjustDynamicWidthElements();
	});
}

// listen to other header elements and readjust when they show up
staticWidthElemNames.forEach(function (name) {
	events.on(`_${name}-initialized`, elem => addStaticWidthElem(name, elem), true);
});

function addStaticWidthElem(name, elem) {
	staticElems[name] = elem;
	adjustDynamicWidthElements();
}
