/* eslint-disable no-console */
// This file will contain the functions for processing the passport data
// in a way that it can be consumed by the components like org switcher
// It also enables showing full page org app switcher
import * as events from './event-bus';
import * as store from './store';
import { doCall } from './ajax';

export const setStateFromContext = context => {
	const { userInfo } = context;

	if (!userInfo.domain || !userInfo.application || !userInfo.role) {
		console.warn('User context unavailable');
		return;
	}

	try {
		store.set('org-switcher-data', buildOrgSwitcherData(context));
		store.set('app-switcher-data', buildAppSwitcherData(context));
		store.set('user-data', buildUserData(context));
		store.set('roles', buildUserRoleData(context));
	} catch (err) {
		console.error('Initialization error');
	}
};

function buildOrgSwitcherData(context) {
	const { userInfo, authProfile } = context;

	return {
		currentOrg: userInfo.domain.id,
		orgs: authProfile.domains.map(d => ({ id: d.id, name: d.name }))
	};
}

function buildAppSwitcherData(context) {
	const { userInfo, authProfile } = context;

	const apps = [];
	authProfile.domains
		.filter(d => d.id === userInfo.domain.id)
		.forEach(domain => {
			domain.applications.forEach(app => {
				apps.push({
					id: app.id,
					name: app.name,
					url: app.address
				});
			});
		});

	return {
		currentApp: userInfo.application.id,
		apps
	};
}

// transforms userInfo into sidekick user-data
function buildUserData(context) {
	const { userInfo } = context;
	const roleData = buildUserRoleData(context);

	return {
		personId: userInfo.person.id,
		email: userInfo.person.email,
		firstName: userInfo.person.name.firstName,
		lastName: userInfo.person.name.lastName,
		roles: roleData.roles.map(role => role.name),
	};
}

// gets all of the users roles for the current domain and application combination
function buildUserRoleData(context) {
	const { userInfo, authProfile } = context;
	const domain = authProfile.domains.find(d => d.id === userInfo.domain.id);
	const application = domain.applications.find(app => app.shortName === userInfo.application.shortName);

	return {
		currentRole: userInfo.role.id,
		roles: application.roles
	};
}

export const handleInactivity = () => {
	//const idleDuration = 3540; // 59 minutes
	const idleDuration = 2100; //35 minutes for testing
	const countdownTimer = 120; // 2 minute; Total session time = 1 hour
	const activityEvents = ['click', 'mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];

	let idleTimeout;

	function resetIdleTimeout() {
		if (idleTimeout) {
			clearTimeout(idleTimeout);
		}

		idleTimeout = setTimeout(() => {
			clearInterval(idleTimeout);
			store.set('session-timeout', { timer: countdownTimer });
		}, idleDuration * 1000);
	}

	resetIdleTimeout();

	activityEvents.forEach((evt) =>
		document.addEventListener(evt, resetIdleTimeout, false)
	);

	// reload the page to refresh the session timeout
	events.on('session.extend', () => {
		location.reload();
	});

	// force logout when timer ends
	events.on('session-timeout.timer-end', () => {
		events.emit('session.logout.timeout');
	});
};

export const getUserContext = () => {
	get('/!/usercontext', (err, data) => {
		if (err) { return console.error('User context unavailable!'); }
		const { userInfo, authorizationProfile: authProfile, impersonator, passportBaseUrl = '' } = data;
		store.set('passport-data', { userInfo, authProfile, impersonator, passportBaseUrl, legacyFormattedData: getLegacyFormattedData(data) });
		events.emit('header.impersonation', !!impersonator);
	});
};

export const setUserContext = context => {
	const params = Object.keys(context).map(k => `!${k}=${context[k]}`).join('&');

	get(`/!/usercontext?${params}`, (err, data) => {
		if (err) { return; }
		const { userInfo, authorizationProfile: authProfile, impersonator, passportBaseUrl = '' } = data;
		store.set('passport-data', { userInfo, authProfile, impersonator, passportBaseUrl, legacyFormattedData: getLegacyFormattedData(data) });
	});
};

export const endImpersonation = () => {
	del('/!/impersonation', (err, data) => {
		if (err) { return; }
		if (data.returnUrl) {
			window.location.href = data.returnUrl;
		}
	});
};

// TODO - To be removed after IDM sunset
function getLegacyFormattedData(passportData) {
	const legacyFormattedData = {};
	const { userInfo, authorizationProfile } = passportData;

	// usercontext is required for legacyFormattedData but is not always available (My Frontline)
	if (!userInfo.domain || !userInfo.application || !userInfo.role) {
		return;
	}

	legacyFormattedData.productId = userInfo.application.shortName.toUpperCase();
	legacyFormattedData.roles = authorizationProfile.domains.find(domain => domain.id === userInfo.domain.id).applications.find(app => app.id === userInfo.application.id).roles.map(role => role.name);
	legacyFormattedData.userContext = {
		Disabled: false,
		OrganizationId: userInfo.domain.id,
		OrganizationName: userInfo.domain.name,
		ProductId: userInfo.application.shortName.toUpperCase(),
		ProductLegacyName: null,
		ProductName: userInfo.application.name,
		Url: '' // TODO - To be decided later
	};
	legacyFormattedData.user = {
		email: userInfo.person.email,
		emailConfirmed: true, // TODO - to be checked
		firstName: userInfo.person.name.firstName,
		isFrontlineUser: true, // TODO - to be checked
		lastName: userInfo.person.name.lastName,
		profileId: userInfo.person.id,
		roles: legacyFormattedData.roles
	};
	legacyFormattedData.access = [];
	authorizationProfile.domains.some(domain => {
		if (domain.id === userInfo.domain.id) {
			domain.applications.forEach(app => {
				legacyFormattedData.access.push({
					Disabled: false,
					OrganizationId: domain.id,
					OrganizationName: domain.name,
					ProductId: app.shortName,
					ProductLegacyName: null,
					ProductName: app.name,
					Url: '' // TODO - To be decided later
				});
			});
			return true;
		}
	});
	return legacyFormattedData;
}

// shorthand get
function get(url, cb, headers = []) {
	const useCredentials = true;
	doCall('GET', url, null, createResponseHandler(cb), [{ key: 'Accept', value: '*/*' }].concat(headers), null, useCredentials);
};

// shorthand post
// function post(url, data, cb, headers = []) {
// 	doCall('POST', url, JSON.stringify(data), createResponseHandler(cb), [{ key: 'Content-Type', value: 'application/json' }].concat(headers));
// };

// shorthand delete
function del(url, cb, headers = []) {
	doCall('DELETE', url, null, createResponseHandler(cb), [{ key: 'Accept', value: '*/*' }].concat(headers));
};

function createResponseHandler(cb = () => {}) {
	return function (statusCode, responseText, headersStr, statusText) {
		// handle error state with error-first callback
		if (400 <= statusCode && statusCode <= 500) {
			cb(Error(statusText));
		}
		if (responseText && responseText.length) {
			cb(null, JSON.parse(responseText));
		} else {
			cb(null);
		}
	};
}
