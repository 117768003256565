import * as dom from '../../util/easy-dom'; // eslint-disable-line no-unused-vars
import * as events from '../../util/event-bus';

/**
 * Lodas a generic error page
 * @class Sidekick.SiteErrors
 * @memberOf Sidekick
 * @param {EasyDom} elem - Container {@link EasyDom} element
 * @param {Object} attrs
 * @param {Object} ctx
 * @param {Function} i18n - translation function
 * @emits session.logout - Thrown when the logout button is clicked
 * @listens site-errors -
 */
export default function (elem, attrs, ctx, i18n) {

	/////
	// find dom elements that we care about
	//
	const domSignInLink = elem.find('.sk--sign-in-link')[0];

	/////
	// listen for events and take action
	//
	events.on('site-errors', () => {
		elem.addClass('show');
	});
	events.on('site-errors.clear', () => {
		elem.removeClass('show');
	});

	domSignInLink.on('click', evt => {
		evt.stopPropagation();
		evt.preventDefault();

		// reload the page on the current url so we come back to what we were doing.
		window.location.reload();
	});
};
