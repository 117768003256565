import {deprecatedMsg} from './deprecated';
import {isObject, isBoolean} from './type-checkers';

const handlerMap = {}; // map of all handlers for all events
const terminalEvents = []; // events that are handled by terminal handlers (only 1 allowed)
const lastEventCache = {}; // map of last data emitted for each event (for greedy event handlers)
const backwardCompatEvents = {
	'session.logout': 'header.user-info.logout'
};
const deprecatedEvents = Object.keys(backwardCompatEvents).reduce(function (acc, key) {
	acc[backwardCompatEvents[key]] = key;
	return acc;
}, {});
export const logErr = msg => console && console.error && console.error(`SIDEKICK => ${msg}`); // eslint-disable-line

/**
 * Event Bus Module
 * @module Sidekick.EventBus
 * @memberOf Sidekick
 */

/**
 * Registers an event listener.
 * @function on
 * @instance
 * @param  {String} evt - Event to respond to
 * @param  {Function} handler - function to handle the event
 * @param  {Object} [config] - the event handler config
 * @param  {Boolean} [config.greedy=false] - execute immediately with last emitted data if possible
 * @param  {Boolean} [config.once=false] - Run once?
 * @param  {Boolean} [config.terminal=false] - Do not allow other handlers to listen to this event?
 * @return {Function} - Deregister function
 */
export const on = function(evt, handler, configOrGreedy = false, once = false) {
	let terminal = false;
	let greedy = false;
	if (isObject(configOrGreedy)) {
		const cfg = configOrGreedy;
		if (cfg.greedy != null) { greedy = cfg.greedy; }
		if (cfg.once != null) { once = cfg.once; }
		if (cfg.terminal != null) { terminal = cfg.terminal; }
	} else if (isBoolean(configOrGreedy)) {
		greedy = configOrGreedy;
	}

	const handlerList = handlerMap[evt] = (handlerMap[evt] || []);
	if (terminalEvents.includes(evt)) {
		logErr(`the event "${evt}" is already handled by a terminal event. No additional handlers can be registered.`);
		return;
	}
	if (terminal === true) {
		// clear other handlers if this one is terminal
		const hCount = handlerList.length;
		if (hCount > 0) {
			handlerList.length = 0;
			logErr(`${hCount} "${evt}" event handler(s) have been removed due to a terminal handler registration.`);
		}
		// add it to the terminal events listen
		terminalEvents.push(evt);
	}

	// check if this handler is already registered with this event
	if (handlerList.includes(handler)) {
		logErr(`the event "${evt}" is already handled by ${handler}`);
		return;
	}

	handler.$once = once;
	handlerList.push(handler);

	if (greedy && lastEventCache[evt]) {
		handler.apply(null, lastEventCache[evt]);
	}

	// return a teardown function
	return () => off(evt, handler);
};


/**
 * Registers an event listener to run once.
 * @function once
 * @instance
 * @param  {String} evt - Event to respond to
 * @param  {Function} handler - function to handle the event
 * @param  {Boolean} [greedy=false] -
 * @return {Function}
 */
export const once = function(evt, handler, greedy = false) {
	return on(evt, handler, {greedy, once: true});
};


/**
 * Deregisters an event listener.
 * @function off
 * @instance
 * @param  {String} evt - Event to respond to
 * @param  {Function} handler - function to handle the event
 */
export const off = function(evt, handler) {
	// we have no handlers for this event, get out
	if (handlerMap[evt] == null) { return; }
	// we are not removing a specific handler, remove all
	if (handler == null) { handlerMap[evt].length = 0; }

	// remove just the one handler
	const handlerList = handlerMap[evt];
	const idx = handlerList.lastIndexOf(handler);
	if (idx === -1) { return; }
	handlerList.splice(idx, 1);
};


/**
 * Emits an event.
 * @function emit
 * @instance
 * @param  {String} evt - Event to respond to
 * @param  {...*} args - Parameters to pass to the handler function for this event
 */
export const emit = function(evt, ...args) {
	// fire deprecated events for backward compatibility
	if (backwardCompatEvents.hasOwnProperty(evt)) {
		emit.apply(null, [backwardCompatEvents[evt], ...args]); // re-emit
	}

	lastEventCache[evt] = args;
	if (!handlerMap[evt]) { return; }
	const handlerList = handlerMap[evt];
	for (let i = handlerList.length; i-- ;) {
		handlerList[i].apply(null, args);
		if (handlerList[i].$once === true) {
			off(evt, handlerList[i]);
		}
	}
};

/**
 * Deregisters all event listeners.
 * @function clear
 * @instance
 */
export const clear = function() {
	terminalEvents.length = 0;
	Object.keys(handlerMap).forEach(k => delete handlerMap[k]);
	Object.keys(lastEventCache).forEach(k => delete lastEventCache[k]);
};

/**
 * The object that exposes the events public api and logs warnings when internal
 * events are emitted or listened to
 */
export const publicApi = {
	on: warnForInternalEvents(on),
	once: warnForInternalEvents(once),
	emit: warnForInternalEvents(emit),
	clear
};

function warnForInternalEvents(func) {
	return function (...args) {
		const evt = args[0];
		/* eslint-disable no-console */
		if (evt.startsWith('_') && console && console.error) {
			logErr(`the event "${evt}" is internal and not to be used`);
		}

		// warn about deprecated events
		if (deprecatedEvents.hasOwnProperty(evt)) {
			deprecatedMsg(`The "${evt}" event`, `"${deprecatedEvents[evt]}"`);
		}

		func.apply(null, args);
	};
}
