import * as dom from '../../util/easy-dom';
import * as events from '../../util/event-bus';
import {pathMatchesRoute} from '../../util/route-helpers';
import {isArray} from '../../util/type-checkers';

export default function(elem, attrs, ctx, i18n) {

	// backward compatability with item.route value
	ctx.url = ctx.url || ctx.route || null;
	if (ctx.isPlatformMenu) { elem.addClass('sk--platform-menu'); }

	let subMenu = null;
	if (ctx.children && ctx.children.length) {
		const subMenuType = (shouldWeMega(ctx.children) ? 'mega-menu' : 'submenu');
		subMenu = dom.parse(`<div sk-nav-item-${subMenuType} sub-level="1" tabindex="-1"></div>`);
		subMenu._.context = ctx.children;
		elem.append(subMenu);
		elem.addClass('sk--has-children');
	} else {
		var a = elem.find('.sk--main-menu-item-container')[0];
		if (ctx.target) {
			a.attr('target', ctx.target);
		}
		elem.addClass('sk--menu-action');
		const menuType = (ctx.isPlatformMenu ? 'plat-menu-item' : 'menu-item');
		elem.on('click', evt => events.emit(`nav.${menuType}-clicked`, ctx, evt));
	}

	if (ctx.badgeEvent != null) {
		const domBadge = elem.find('.sk--badge')[0];

		events.on(ctx.badgeEvent, data => {
			domBadge.css('display', 'none');
			domBadge.removeClass('sk--max');

			if (data === true) {
				domBadge.css('display', 'block');
				domBadge.addClass('sk--empty');
			} else if (Number.isInteger(data)) {
				if (data < 1) { return; }

				domBadge.css('display', 'block');

				domBadge.css('display', 'block');
				domBadge._.innerHTML = Math.min(data, 99);

				if (data > 99) {
					domBadge.addClass('sk--max');
					domBadge._.innerHTML += '+';
				}
			}
		}, true);
	}

	events.on('_location.path-change', highlightRoute);

	function highlightRoute(path) {
		const highlighted = pathMatchesRoute(path, ctx.url, ctx.routeMatcher, ctx.baseUrl);
		elem.toggleClass('highlight', highlighted);
	}

	function shouldWeMega(children) {
		const noOrphanNodes = children.every(n => isArray(n.children));
		const totalNodes = countNodes(children);
		return noOrphanNodes && totalNodes <= 30;
	}

	function countNodes(children) {
		// children must be an array
		if (!isArray(children)) { return 0; }
		return children.reduce(function (total, child) {
			++total;
			if (isArray(child.children)) { total += child.children.length; }
			return total;
		}, 0);
	}
};
