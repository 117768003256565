import * as env from '../../util/env';
import * as events from '../../util/event-bus';
import { get, post } from '../../util/idm-helpers';
import * as store from '../../util/store';

/**
 * Container class
 * @class Sidekick.Announcements
 * @memberOf Sidekick
 * @param {EasyDom} elem - Container {@link EasyDom} element
 * @param {Object} attrs
 * @param {Object} ctx (idm-data)
 * @param {Function} i18n
 */
export default function(elem, attrs, ctx, i18n) {
	/////
	// dom elements we require access to
	//
	const domCloseButton = elem.find('.close')[0];
	const domAnchor = elem.find('.sk-announcements-anchor')[0];
	const domTitle = elem.find('.sk-announcements-title')[0];
	const domContent = elem.find('.sk-announcements-content')[0];
	const domContainer = elem.find('.sk-announcements-container')[0];

	const fortyEightHours = 172800000;
	/////
	// get announcements endpoints
	//
	const idm = store.get('idm');
	const envName = env.environmentFromIdmGatewayUrl(idm.idGatewayUrl);
	const { announcementsBaseUrl } = env[envName];

	const announcementsControllerUrl = `${announcementsBaseUrl}/api/v1/announcements`;
	const currentAnnouncementEndpoint = `${announcementsControllerUrl}/current?userId=${ctx.user.profileId}`;

	let announcementId;

	// Only check for announcements if enabled
	events.once('feature-flags-ready-cc', flagsSvc => {
		flagsSvc.checkState('sk_platform_enable_announcements').then(enabled => {
			if (enabled) {
				get(currentAnnouncementEndpoint, function (result) {
					if (result && result.data && result.data.length) {
						let { id, startShowingIn = 0, stopShowingIn = 0, title, content, moreInfoLink } = result.data[0];

						announcementId = id;

						if (startShowingIn > fortyEightHours || stopShowingIn <= 0 || title.length <= 0) { return; }

						if (stopShowingIn > fortyEightHours) {
							stopShowingIn = fortyEightHours;
						}

						setTimeout(() => {
							if (moreInfoLink) {
								domAnchor.attr('href', moreInfoLink);
								domAnchor.removeClass('sk-hidden');
							}
							domTitle._.textContent = title;
							domContent._.textContent = content;
							domContainer.removeClass('sk-hidden');
						}, startShowingIn);

						setTimeout(() => {
							domContainer.addClass('sk-hidden');
						}, stopShowingIn);
					}
				}, [{
					key: 'Cache-Control',
					value: 'no-cache'
				}, {
					key: 'Pragma',
					value: 'no-cache'
				}]);
			}
		});
	}, { greedy: true });

	/////
	// listen for events and take action
	//
	domCloseButton.on('click', () => {
		if (announcementId) {
			const ackAnnouncementEndpoint = `${announcementsControllerUrl}/${announcementId}/ack?userId=${ctx.user.profileId}`;
			post(ackAnnouncementEndpoint);
			domContainer.addClass('sk-hidden');
		}
	});
};


