/**
 * SuperSuit Loader
 * @class Sidekick.Loader
 * @memberOf Sidekick
 * @param {EasyDom} elem - Container {@link EasyDom} element
 * @param {Object} attrs
 * @param {String} attrs.statusText - text to show above the finger loader
 * @param {Null} attrs.fill - undefined if not in "fill" mode, not undefined if in fill mode
 * @param {Null} ctx - not used
 * @param {Function} i18n - translation function
 * @listens loader.show - show/hide the loader
 */
export default function (elem, attrs, ctx, i18n) {
	/////
	// handle iniital state and data changes
	//
	if (attrs.fill !== undefined) { elem.addClass('sk-fill-container'); }
};
