'use strict';
import 'es6-promise/auto';

// Object.assign
function objectAssign(target, varArgs) { // .length of function is 2
	'use strict';
	if (target == null) { // TypeError if undefined or null
		throw new TypeError('Cannot convert undefined or null to object');
	}

	var to = Object(target);

	for (var index = 1; index < arguments.length; index++) {
		var nextSource = arguments[index];

		if (nextSource != null) { // Skip over if undefined or null
			for (var nextKey in nextSource) {
				// Avoid bugs when hasOwnProperty is shadowed
				if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
					to[nextKey] = nextSource[nextKey];
				}
			}
		}
	}
	return to;
}

// Array.includes
function arrayIncludes(searchElement /*, fromIndex*/ ) {
	var O = Object(this);
	var len = parseInt(O.length, 10) || 0;
	if (len === 0) {
		return false;
	}
	var n = parseInt(arguments[1], 10) || 0;
	var k;
	if (n >= 0) {
		k = n;
	} else {
		k = len + n;
		if (k < 0) {k = 0;}
	}
	var currentElement;
	while (k < len) {
		currentElement = O[k];
		if (searchElement === currentElement ||
			(searchElement !== searchElement && currentElement !== currentElement)) { // NaN !== NaN
				return true;
		}
		k++;
	}
	return false;
}

// String.endsWidth
function stringEndsWith(searchString, position) {
	var subjectString = this.toString();
	if (typeof position !== 'number' || !isFinite(position) || Math.floor(position) !== position || position > subjectString.length) {
		position = subjectString.length;
	}
	position -= searchString.length;
	var lastIndex = subjectString.indexOf(searchString, position);
	return lastIndex !== -1 && lastIndex === position;
}

// String.startsWith
function stringStartsWith(searchString, position){
	position = position || 0;
	return this.substr(position, searchString.length) === searchString;
}


function arrayFind(predicate) {
	if (this == null) {
		throw new TypeError('Array.prototype.find called on null or undefined');
	}
	if (typeof predicate !== 'function') {
		throw new TypeError('predicate must be a function');
	}
	var list = Object(this);
	var len = list.length >>> 0; // eslint-disable-line no-bitwise
	var thisArg = arguments[1];
	var value;

	for (var i = 0; i < len; i++) {
		value = list[i];
		if (predicate.call(thisArg, value, i, list)) {
			return value;
		}
	}
	return undefined;
}

function arrayFindIndex(predicate) {
	if (this == null) {
		throw new TypeError('Array.prototype.findIndex called on null or undefined');
	}
	if (typeof predicate !== 'function') {
		throw new TypeError('predicate must be a function');
	}
	var list = Object(this);
	var len = list.length >>> 0; // eslint-disable-line no-bitwise
	var thisArg = arguments[1];
	var value;

	for (var i = 0; i < len; i++) {
		value = list[i];
		if (predicate.call(thisArg, value, i, list)) {
			return i;
		}
	}
	return -1;
}

// Production steps of ECMA-262, Edition 6, 22.1.2.1
function arrayFrom() {
	var toStr = Object.prototype.toString;
	var isCallable = function (fn) {
		return typeof fn === 'function' || toStr.call(fn) === '[object Function]';
	};
	var toInteger = function (value) {
		var number = Number(value);
		if (isNaN(number)) { return 0; }
		if (number === 0 || !isFinite(number)) { return number; }
		return (number > 0 ? 1 : -1) * Math.floor(Math.abs(number));
	};
	var maxSafeInteger = Math.pow(2, 53) - 1;
	var toLength = function (value) {
		var len = toInteger(value);
		return Math.min(Math.max(len, 0), maxSafeInteger);
	};

	// The length property of the from method is 1.
	return function from(arrayLike/*, mapFn, thisArg */) {
		// 1. Let C be the this value.
		var C = this;

		// 2. Let items be ToObject(arrayLike).
		var items = Object(arrayLike);

		// 3. ReturnIfAbrupt(items).
		if (arrayLike == null) {
			throw new TypeError('Array.from requires an array-like object - not null or undefined');
		}

		// 4. If mapfn is undefined, then let mapping be false.
		var mapFn = arguments.length > 1 ? arguments[1] : void undefined;
		var T;
		if (typeof mapFn !== 'undefined') {
			// 5. else
			// 5. a If IsCallable(mapfn) is false, throw a TypeError exception.
			if (!isCallable(mapFn)) {
				throw new TypeError('Array.from: when provided, the second argument must be a function');
			}

			// 5. b. If thisArg was supplied, let T be thisArg; else let T be undefined.
			if (arguments.length > 2) {
				T = arguments[2];
			}
		}

		// 10. Let lenValue be Get(items, "length").
		// 11. Let len be ToLength(lenValue).
		var len = toLength(items.length);

		// 13. If IsConstructor(C) is true, then
		// 13. a. Let A be the result of calling the [[Construct]] internal method
		// of C with an argument list containing the single item len.
		// 14. a. Else, Let A be ArrayCreate(len).
		var A = isCallable(C) ? Object(new C(len)) : new Array(len);

		// 16. Let k be 0.
		var k = 0;
		// 17. Repeat, while k < len… (also steps a - h)
		var kValue;
		while (k < len) {
			kValue = items[k];
			if (mapFn) {
				A[k] = typeof T === 'undefined' ? mapFn(kValue, k) : mapFn.call(T, kValue, k);
			} else {
				A[k] = kValue;
			}
			k += 1;
		}
		// 18. Let putStatus be Put(A, "length", len, true).
		A.length = len;
		// 20. Return A.
		return A;
	};
}

// Number.isInteger polyfill
function numberIsInteger(value) {
	return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
}

/////
// Run the polyfills
//
if (typeof Object.assign !== 'function') {
	Object.defineProperty(Object, 'assign', {
		configurable: true,
		writable: true,
		value: objectAssign
	});
}
if (!Array.prototype.includes) { Array.prototype.includes = arrayIncludes; }
if (!String.prototype.endsWith) { String.prototype.endsWith = stringEndsWith; }
if (!String.prototype.startsWith) { String.prototype.startsWith = stringStartsWith; }
if (!Array.prototype.find) { Array.prototype.find = arrayFind; }
if (!Array.prototype.findIndex) { Array.prototype.findIndex = arrayFindIndex; }
if (!Number.isInteger) { Number.isInteger = numberIsInteger; }
if (!Array.from) { Array.from = arrayFrom(); }
