import * as dom from '../../util/easy-dom';
import * as events from '../../util/event-bus';
import * as keyPress from '../../util/key-press-helpers';
import {pathMatchesRoute} from '../../util/route-helpers';

export default function (elem, attrs, ctx, i18n) {
	const {subLevel} = attrs;

	const menuGroup = elem.find('.menu-group')[0];
	elem.addClass(`sub-level-${subLevel}`);

	const subMenus = [];
	const menuItems = ctx.map(function(item, idx) {
		// backward compatability with item.route value
		item.url = item.url || item.route || null;

		const href = item.url || '#';
		const target = item.url && item.target ? ` target="${item.target}"` : '';

		const kids = item.children;
		const hasKids = !!(kids && kids.length);
		const el = dom.parse(`
			<li class="sub-menu-item">
				<a class="sub-menu-item-container" title="${item.title}" href="${href}"${target} role="menu-item">
					<span>${item.title}</span>
					${hasKids ? '<i class="sk--more-items fa fa-angle-right"></i>' : ''}
				</a>
			</li>
		`);
		var subMenu;

		if (hasKids) {
			subMenu = dom.parse(`<div sk-nav-item-submenu sub-level="${parseInt(subLevel, 10) + 1}" tabindex="-1"></div>`);
			subMenu._.context = kids;
			subMenus.push(subMenu);
			// el.append(subMenu);
			subMenu.on('keydown', (evt) => {
				switch(true) {
				case keyPress.isEscape(evt):
					subMenu.removeClass('sk-submenu-active');
					el.removeClass('sk-submenu-active');
					const [link] = el.find('a');
					if (link != null) {
						link._.focus();
					}
					evt.stopPropagation();
					break;
				}
			});
		} else {
			el.addClass('sk--menu-action');
		}

		// add menuName value if present
		if (typeof item.menuName === 'string') {
			el.data('menu-name', item.menuName);
		}

		el.find('.sub-menu-item-container')[0].on('click', function(evt) {
			if (!hasKids) { events.emit('nav.menu-item-clicked', item, evt); }
			if (hasKids) { evt.preventDefault(); }

			elem.find('.sk-submenu-active').forEach(function (m) {
				if (m !== el && m !== subMenu) { m.removeClass('sk-submenu-active'); }
			});

			if (hasKids) {
				subMenu.toggleClass('sk-submenu-active');
				subMenu._.focus();
				el.toggleClass('sk-submenu-active');
			}
		});

		return el;
	});
	menuGroup.children(menuItems);
	elem.append(subMenus);

	events.on('_location.path-change', highlightRoute);
	function highlightRoute(path) {
		if (path == null) { return; }

		menuItems.forEach(function (el, idx) {
			const ictx = ctx[idx];
			const highlighted = pathMatchesRoute(path, ictx.url, ictx.routeMatcher);
			el.toggleClass('highlight', highlighted);
		});
	}
};
