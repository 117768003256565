/**
 * Internationalization (i18n) for Sidekick
 * @module Sidekick.Translations
 * @memberOf Sidekick
 */

var language = 'en';
var current = {};
var langTokens = [];

const langs = {
	'fr': {
		'You have been signed out.': 'Vous avez été déconnecté.',
		'For your security and protection of your data, you have been signed out due to inactivity.': 'Pour votre sécurité et protection de vos données, vous avez été déconnecté en raison de l\'inactivité.',
		'Sign In Again to Continue': 'Se connecter pour continuer',
		'Account Settings': 'Paramètres du compte',
		'All rights reserved': 'Tous les droits sont réservés',
		'clear input': 'entrée libre',
		'close panel': 'fermer le panneau',
		'Contact Your Organization': 'Contactez votre organisme',
		'Due to inactivity, your session will end and you will be signed out. Do you want to stay signed in?': 'En raison de l\'inactivité, votre session prendra fin et vous serez déconnecté. Voulez-vous rester connecté?',
		'Fax': 'Fax',
		'Help Resources': 'Ressources d\'aide',
		'Navigation': 'La navigation',
		'No, Sign Me Out': 'Non, Signez-moi',
		'No Results Found': 'Aucun résultat trouvé',
		'Notifications': 'Notifications',
		'notifications': 'notifications',
		'Phone': 'Téléphone',
		'Recent Notifications': 'Notifications récentes',
		'Search': 'Chercher',
		'Search Organizations': 'Organismes de recherche',
		'Search Results': 'Résultats de la recherche',
		'Select an Application': 'Sélectionnez une application',
		'Select an Organization': 'Sélectionnez une organisation',
		'Sign Out': 'Se déconnecter',
		'Skip to Main Content': 'Passer au contenu principal',
		'Switch Apps': 'Applications de commutation',
		'Switch Organizations': 'Organisations Switch',
		'System Activity': 'Activité du système',
		'Yes, Stay Signed In': 'Oui, Restez connecté',
		'You have no notifications': 'Vous avez aucune notification',
		'You will be signed out soon.': 'Vous serez bientôt déconnecté',
		'Your Frontline Account': 'Votre compte Frontline',
		'Your Solutions': 'Vos Solutions',
		'viewing as': 'Voir Comme',
		'Minimize Navigation': 'Minimiser Navigation',
		'Expand Navigation': 'Développer Navigation',
		'Toggle Navigation': 'Navigation Bascule',
		'System Configuration': 'Configuration du système',
		'Manage User Access': 'Gérer l\'accès des utilisateurs',
		'Additional Resources': 'Ressources additionnelles',
		'Resource Library': 'Bibliothèque de ressources',
		'Data Exporter': 'Exportateur de données',
		'User Configuration': 'Paramétrage utilisateur',
		'Frontline User Permissions':
			'Autorisations des utilisateurs de première ligne',
		'Browse Library': 'Parcourir la bibliothèque',
		'My Resource History': 'Mon historique des ressources',
		'My Groups': 'Mes groupes',
		'Library': 'Bibliothèque',
		'Administration': 'Administration',
		'My Organization Resources': 'Ressources de mon organisation',
		'Global Directory': 'Annuaire Global',
		'Learning Designer Admin' : 'Administrateur du concepteur d\'apprentissage',
		'Reports' : 'Rapports',
		'Manage Content Curator': 'Gérer le conservateur de contenu',
		'Micro-Credential Assessor Tool': 'Outil d\'évaluation des micro-informations d\'identification',
		'Employee Directory': 'Annuaire des Employés',
		'My Profile': 'Mon profil',
		'My Resources': 'Mes ressources',
		'Staff Directory': 'Répertoire personnel',
		'My Frontline': 'My Frontline',
		'Stop Impersonation': 'Stop Impersonation',
		'Collaborative Video Rubrics': 'Rubriques vidéo collaboratives'
	},
	'it': {
		'You have been signed out.': 'Sei stato lasciato fuori conto.',
		'For your security and protection of your data, you have been signed out due to inactivity.': 'Per la tua sicurezza e la protezione dei tuoi dati, sei uscito per l\'inattività.',
		'Sign In Again to Continue': 'Accedi nuovamente per continuare',
		'Account Settings': 'Impostazioni dell \'account',
		'All rights reserved': 'Tutti i diritti riservati',
		'clear input': 'ingresso libero',
		'close panel': 'Chiudi pannello',
		'Contact Your Organization': 'Contattare la propria organizzazione',
		'Due to inactivity, your session will end and you will be signed out. Do you want to stay signed in?': 'A causa di inattività, la sessione si concluderà e si saranno firmati fuori. Vuoi rimanere firmato a?',
		'Fax': 'Fax',
		'Help Resources': 'Risorse di assistenza',
		'Navigation': 'Navigazione',
		'No, Sign Me Out': 'No, Mi Esci',
		'No Results Found': 'Nessun risultato trovato',
		'Notifications': 'notifiche',
		'notifications': 'notifiche',
		'Phone': 'Telefono',
		'Recent Notifications': 'Notifiche recenti',
		'Search': 'Ricerca',
		'Search Organizations': 'Le organizzazioni di ricerca',
		'Search Results': 'risultati di ricerca',
		'Select an Application': 'Selezionare un\'applicazione',
		'Select an Organization': 'Seleziona una Organizzazione',
		'Sign Out': 'Disconnessione',
		'Skip to Main Content': 'Vai al contenuto principale',
		'Switch Apps': 'applicazioni Interruttore',
		'Switch Organizations': 'Organizzazioni di commutazione',
		'System Activity': 'Attività del sistema',
		'Yes, Stay Signed In': 'Sì, Resta connesso',
		'You have no notifications': 'Non ci sono notifiche',
		'You will be signed out soon.': 'Verrà firmato presto',
		'Your Frontline Account': 'Il tuo account Frontline',
		'Your Solutions': 'Le nostre soluzioni',
		'viewing as': 'visualizzazione come',
		'Minimize Navigation': 'Ridurre Navigazione',
		'Expand Navigation': 'Espandere Navigazione',
		'Toggle Navigation': 'Attiva Navigazione',
		'System Configuration': 'Configurazione di sistema',
		'Manage User Access': 'Gestisci l\'accesso degli utenti',
		'Additional Resources': 'Risorse addizionali',
		'Resource Library': 'Libreria delle risorse',
		'Data Exporter': 'Esportatore di dati',
		'User Configuration': 'Configurazione utente',
		'Frontline User Permissions': 'Autorizzazioni utente in prima linea',
		'Browse Library': 'Sfoglia la biblioteca',
		'My Resource History': 'La mia storia delle risorse',
		'My Groups': 'I miei gruppi',
		'Library': 'Biblioteca',
		'Administration': 'Amministrazione',
		'My Organization Resources': 'Risorse della mia organizzazione',
		'Global Directory': 'Directory Globale',
		'Learning Designer Admin' : 'Amministratore di Learning Designer',
		'Reports' : 'Rapporti',
		'Manage Content Curator': 'Gestisci curatore dei contenuti',
		'Micro-Credential Assessor Tool': 'Strumento di valutazione delle micro credenziali',
		'Employee Directory': 'Directory dei Dipendenti',
		'My Profile': 'Il Mio Profilo',
		'My Resources': 'Le mie risorse',
		'Staff Directory': 'Directory del Personale',
		'My Frontline': 'My Frontline',
		'Stop Impersonation': 'Stop Impersonation',
		'Collaborative Video Rubrics': 'Rubriche video collaborative'
	},
	'es': {
		'You have been signed out.': 'Se ha cerrado la sesión.',
		'For your security and protection of your data, you have been signed out due to inactivity.': 'Para su seguridad y protección de sus datos, se ha cerrado la sesión debido a la inactividad.',
		'Sign In Again to Continue': 'Iniciar sesión de nuevo para continuar',
		'Account Settings': 'Configuraciones de la cuenta',
		'All rights reserved': 'Todos los derechos reservados',
		'clear input': 'entrada de borrado',
		'close panel': 'panel de cierre',
		'Contact Your Organization': 'Póngase en contacto con su organización',
		'Due to inactivity, your session will end and you will be signed out. Do you want to stay signed in?': 'Debido a la inactividad, su sesión finalizará y se cerrará sesión. ¿Quiere permanecer conectado?',
		'Fax': 'Fax',
		'Help Resources': 'Recursos de ayuda',
		'Navigation': 'Navegación',
		'No, Sign Me Out': 'No, firmeme',
		'No Results Found': 'No se han encontrado resultados',
		'Notifications': 'Notificaciones',
		'notifications': 'notificaciones',
		'Phone': 'Teléfono',
		'Recent Notifications': 'Notificaciones recientes',
		'Search': 'Buscar',
		'Search Organizations': 'Organizaciones de la búsqueda',
		'Search Results': 'Resultados de la búsqueda',
		'Select an Application': 'Seleccione una aplicación',
		'Select an Organization': 'Seleccione una Organización',
		'Sign Out': 'Desconectar',
		'Skip to Main Content': 'Saltar al contenido principal',
		'Switch Apps': 'Aplicaciones del interruptor',
		'Switch Organizations': 'Organizaciones de conmutación',
		'System Activity': 'Actividad del sistema',
		'Yes, Stay Signed In': 'Sí, permanece firmado',
		'You have no notifications': 'No tiene notificaciones',
		'You will be signed out soon.': 'Saldrá pronto',
		'Your Frontline Account': 'Su cuenta en primera línea',
		'Your Solutions': 'sus Soluciones',
		'viewing as': 'viendo como',
		'Minimize Navigation': 'Minimizar Navegación',
		'Expand Navigation': 'Expandir Navegación',
		'Toggle Navigation': 'Alternar Navegación',
		'System Configuration': 'Configuración del sistema',
		'Manage User Access': 'Administrar acceso de usuario',
		'Additional Resources': 'Recursos adicionales',
		'Resource Library': 'Biblioteca de recursos',
		'Data Exporter': 'Exportador de datos',
		'User Configuration': 'Configuración de usuario',
		'Frontline User Permissions': 'Permisos de usuario de primera línea',
		'Browse Library': 'Navegar por la biblioteca',
		'My Resource History': 'Mi historial de recursos',
		'My Groups': 'Mis grupos',
		'Library': 'Biblioteca',
		'Administration': 'Administración',
		'My Organization Resources': 'Mis recursos de organización',
		'Global Directory': 'Directorio Global',
		'Learning Designer Admin' : 'Administrador del diseñador de aprendizaje',
		'Reports' : 'Informes',
		'Manage Content Curator': 'Gestionar curador de contenido',
		'Micro-Credential Assessor Tool': 'Herramienta de evaluación de micro credenciales',
		'Employee Directory': 'Directorio de Empleados',
		'My Profile': 'Mi Perfil',
		'My Resources': 'Mis Recursos',
		'Staff Directory': 'Directorio de Personal',
		'My Frontline': 'My Frontline',
		'Stop Impersonation': 'Stop Impersonation',
		'Collaborative Video Rubrics': 'Rúbricas de videos colaborativos'
	},
};

/**
 * Sets the language to use.
 * @function init
 * @instance
 * @param  {String} newLang=en - Language to set. Defaults to English.
 */
export function init(newLang) {
	makeLangTokens();
	newLang = newLang.toLowerCase();
	if (langs[newLang] == null) {
		if (newLang.indexOf('-') >= 0) {
			newLang = newLang.split('-')[0];
			if (langs[newLang] == null) {
				newLang = 'en';
			}
		}
	}

	language = newLang;
	if (language === 'en') {
		current = {};
		return;
	}
	current = langs[language];
}

/**
 * Takes an English string and will look up the translation based on the current language.
 * @function i18n
 * @instance
 * @param  {String} str - String to transform
 * @return {String} Returns the translation if exists. Otherwise returns the str passed in.
 */
export function i18n(str) {
	/* eslint-disable no-console */
	if (!langTokens.includes(str) && console && console.error) {
		console.error(`SIDEKICK: "${str}" is not a valid language token`);
	}
	/* eslint-enable no-console */
	return language === 'en' ? str : (current[str] != null ? current[str] : str);
}

function makeLangTokens() {
	const langKeys = Object.keys(langs);
	langTokens = Object.keys(langs[langKeys[0]]).sort();
	const baseSignature = langTokens.join('#');
	langKeys.forEach(function (k) {
		const langSignature = Object.keys(langs[k]).sort().join('#');
		/* eslint-disable no-console */
		if (langSignature !== baseSignature && console && console.error) {
			console.error(`SIDEKICK: Internationalization language signatures are inconsistent (${k})`);
		}
		/* eslint-enable no-console */
	});
}
