import * as events from './event-bus';
import { isStoreSet } from './store-validate';

const store = {};
const eventPrefix = '_store.set';
const publicReadOnly = [
	'idm-data',
	'sk-config',
	'fl-internal-product-ids'
];
const mutuallyExclusiveKeys = {
  'idm': 'passport',
  'passport': 'idm'
};

/**
 * @module Sidekick.Store
 * @memberOf Sidekick
 */

/**
 * Sets a value to the store.
 * @function set
 * @instance
 * @param  {String} key - Key for the value
 * @param  {Function} value - value stored under the key
 * @emits ${eventPrefix}-$key
 */
export const set = (key, value) => {
	const flag = isStoreSet(store, key, mutuallyExclusiveKeys);
	if(flag) {
		throw new Error(`SIDEKICK.STORE: Please clear '${mutuallyExclusiveKeys[key]}' before setting '${key}'`);
	}
	store[key] = value;
	events.emit(`${eventPrefix}-${key}`, value);
};


/**
 * Returns the value stored at the key or null.
 * @function get
 * @instance
 * @param  {String} key
 * @return {*} value - Value (or null) found (or not) at the key.
 */
export const get = (key) => store[key] || null;


/**
 * Creates a listener for this key.
 * @function onValue
 * @instance
 * @param  {String} key - key for the handler
 * @param  {Function} handler - function to handle the event
 */
export const onValue = (key, handler) => events.on(`${eventPrefix}-${key}`, handler, true);


/**
 * Creates a listener for this key that runs once.
 * @function onValueOnce
 * @instance
 * @param  {String} key - key for the handler
 * @param  {Function} handler - function to handle the event
 */
export const onValueOnce = (key, handler) => events.once(`${eventPrefix}-${key}`, handler, true);


/**
 * Returns the value stored at the key or null.
 * @function clear
 * @instance
 */
export const clear = () => Object.keys(store).forEach(k => delete store[k]);

/**
 * The object that exposes the events public api and logs warnings when internal
 * events are emitted or listened to
 */
export const publicApi = {
	set: warnForInternalStore(publicSet),
	get: warnForInternalStore(get),
	onValue: warnForInternalStore(onValue),
	onValueOnce: warnForInternalStore(onValueOnce),
	clear
};

function publicSet(...args) {
	const key = args[0];
	if (key == null) {
		/* eslint-disable no-console */
		if (console && console.error) {
			console.error('SIDEKICK.STORE: the first parameter %ckey<String>%c is required.', 'color:blue;font-weight:bold;', '');
		}
		/* eslint-enable no-console */
		return;
	}
	if ((publicReadOnly.includes(key) || key.startsWith('_'))) {
		/* eslint-disable no-console */
		if (console && console.error) {
			console.error(`SIDEKICK.STORE: "${key}" is a read only value`);
		}
		/* eslint-enable no-console */
		return;
	}
	return set.apply(null, args);
}

function warnForInternalStore(func) {
	return function (...args) {
		const key = args[0];
		if (key == null) {
			/* eslint-disable no-console */
			if (console && console.error) {
				console.error('SIDEKICK.STORE: the first parameter %ckey<String>%c is required.', 'color:blue;font-weight:bold;', '');
			}
			/* eslint-enable no-console */
			return;
		}
		/* eslint-disable no-console */
		if (key.startsWith('_') && console && console.error) {
			console.error(`SIDEKICK.STORE: "${key}" is internal and not to be used`);
		}
		/* eslint-enable no-console */
		return func.apply(null, args);
	};
}
