/**
* @module Sidekick.KeyPressHelper
* @memberOf Sidekick
*/

const keys = {
	' ': 32, // space
	'arrowdown': 40,
	'arrowleft': 37,
	'arrowright': 39,
	'arrowup': 38,
	'enter': 13,
	'escape': 27,
	'tab': 9,
};

/**
 * Determines if the specified key was pressed
 *
 * @param {Object} evt
 *
 * @return {Boolean} Returns true if the specified key was pressed. False otherwise
 */
export function isKey(evt, keyName, which) {
	if (evt == null) { return false; }

	const keyPressed = evt.key && evt.key.toLowerCase() === keyName;
	const whichPressed = evt.which === which;

	return keyPressed || whichPressed;
}

/**
 * Utility function that maps a key name to a keyCode value before passing the event to isKey
 *
 * @param {Object} evt
 * @param {String} keyName
 *
 * @return {Boolean}
 */
function checkKey(evt, keyName) {
	return isKey(evt, keyName, keys[keyName]);
}

/**
 * Determines if ENTER was pressed
 *
 * @param {Object} evt
 *
 * @return {Boolean} Returns true if ENTER was pressed. False otherwise
 */
export function isEnter(evt) {
	return checkKey(evt, 'enter');
}

/**
 * Determines if ESCAPE was pressed
 *
 * @param {Object} evt
 *
 * @return {Boolean} Returns true if ESCAPE was pressed. False otherwise
 */
export function isEscape(evt) {
	return checkKey(evt, 'escape');
}

/**
 * Determines if TAB was pressed
 *
 * @param {Object} evt
 *
 * @return {Boolean} Returns true if TAB was pressed. False otherwise
 */
export function isTab(evt) {
	return checkKey(evt, 'tab');
}

/**
 * Determines if LEFT was pressed
 *
 * @param {Object} evt
 *
 * @return {Boolean} Returns true if LEFT was pressed. False otherwise
 */
export function isLeft(evt) {
	return checkKey(evt, 'arrowleft');
}

/**
 * Determines if UP was pressed
 *
 * @param {Object} evt
 *
 * @return {Boolean} Returns true if UP was pressed. False otherwise
 */
export function isUp(evt) {
	return checkKey(evt, 'arrowup');
}

/**
 * Determines if RIGHT was pressed
 *
 * @param {Object} evt
 *
 * @return {Boolean} Returns true if RIGHT was pressed. False otherwise
 */
export function isRight(evt) {
	return checkKey(evt, 'arrowright');
}

/**
 * Determines if DOWN was pressed
 *
 * @param {Object} evt
 *
 * @return {Boolean} Returns true if DOWN was pressed. False otherwise
 */
export function isDown(evt) {
	return checkKey(evt, 'arrowdown');
}

/**
 * Determines if SPACE was pressed
 *
 * @param {Object} evt
 *
 * @return {Boolean} Returns true if SPACE was pressed. False otherwise
 */
export function isSpace(evt) {
	return checkKey(evt, ' ');
}
