
/**
 * Common string helper functions
 * @module Sidekick.StringHelpers
 * @memberOf Sidekick
 */

/**
 * Turns a space delimited string to dash delimited.
 * @function toDashCase
 * @instance
 * @param  {String} txt - String to transform
 */
export function toDashCase(txt) {
	return splitWords(txt).join('-');
}

/**
 * Turns a space delimited string to camel case.
 * @function toDashCase
 * @instance
 * @param  {String} txt - String to transform
 */
export function toCamelCase(txt) {
	return splitWords(txt)
		.map(function (v,i) {
			if (i === 0) { return v; }
			return v.charAt(0).toUpperCase() + v.substr(1);
		})
		.join('');
}

function splitWords(txt) {
	return txt.toString()
		.replace(/[\s_-]/g, '{!}')
		.replace(/([A-Z])/g, '{!}$1')
		.toLowerCase()
		.split('{!}')
		.filter(function (v) { return !!v.length; });
}
