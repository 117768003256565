/**
 * Ajax Calls
 * @module Sidekick.Ajax
 * @memberOf Sidekick
 */

/**
 * Makes a call
 * @function do
 * @instance
 * @param  {String}   method
 * @param  {String}   url
 * @param  {Object}   post
 * @param  {Function} callback
 * @param  {Array}   headers
 * @param  {Number}   timeout
 * @param  {Boolean}   withCredentials
 * @param  {Object}   responseType
 * @param  {Array}   eventHandlers
 * @param  {Array}   uploadEventHandlers
 */
export function doCall(method, url, post, callback, headers, timeout, withCredentials, responseType, eventHandlers, uploadEventHandlers) {
	var h, i, xhr = new window.XMLHttpRequest();

	xhr.open(method, url, true);

	for (i = 0; i < headers.length; i++) {
		var header = headers[i];
		if (header.value != null) {
			xhr.setRequestHeader(header.key, header.value);
		}
	}

	xhr.onload = function requestLoaded() {
		var statusText = xhr.statusText || '';

		// responseText is the old-school way of retrieving response (supported by IE9)
		// response/responseType properties were introduced in XHR Level2 spec (supported by IE10)
		var response = ('response' in xhr) ? xhr.response : xhr.responseText;

		// normalize IE9 bug (http://bugs.jquery.com/ticket/1450)
		var status = xhr.status === 1223 ? 204 : xhr.status;

		// fix status code when it is 0 (0 status is undocumented).
		// Occurs when accessing file resources or on Android 4.1 stock browser
		// while retrieving files from application cache.
		// if (status === 0) {
		// 	status = response ? 200 : urlResolve(url).protocol == 'file' ? 404 : 0;
		// }

		completeRequest(callback,
			status,
			response,
			xhr.getAllResponseHeaders(),
			statusText);
	};

	var requestError = function() {
		// The response is always empty
		// See https://xhr.spec.whatwg.org/#request-error-steps and https://fetch.spec.whatwg.org/#concept-network-error
		completeRequest(callback, -1, null, null, '');
	};

	xhr.onerror = requestError;
	xhr.onabort = requestError;

	if (eventHandlers != null) {
		for (i = 0; i < eventHandlers.length; i++) {
			h = eventHandlers[i];
			xhr.addEventListener(h.key, h.value);
		}
	}

	if (uploadEventHandlers != null) {
		for (i = 0; i < uploadEventHandlers.length; i++) {
			h = uploadEventHandlers[i];
			xhr.upload.addEventListener(h.key, h.value);
		}
	}

	if (withCredentials) {
		xhr.withCredentials = true;
	}

	if (responseType) {
		try {
			xhr.responseType = responseType;
		} catch (e) {
			// WebKit added support for the json responseType value on 09/03/2013
			// https://bugs.webkit.org/show_bug.cgi?id=73648. Versions of Safari prior to 7 are
			// known to throw when setting the value "json" as the response type. Other older
			// browsers implementing the responseType
			//
			// The json response type can be ignored if not supported, because JSON payloads are
			// parsed on the client-side regardless.
			if (responseType !== 'json') {
				throw e;
			}
		}
	}

	xhr.send(post == null ? null : post);
}

function completeRequest(callback, status, response, headersString, statusText) {
	callback(status, response, headersString, statusText);
}
