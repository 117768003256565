/**
 * @module Sidekick.Store
 * @memberOf Sidekick
 */

/**
 * Validates a key set in the store.
 * @function isStoreSet
 * @instance
 * @param  {String} store - Store in which key to be checked
 * @param  {String} key - Key to be checked
 * @param  {String} mutuallyExclusiveKeys - mutuallyExclusiveKeys map
 */
 export const isStoreSet = (store, key, mutuallyExclusiveKeys) => {
	return !!store[mutuallyExclusiveKeys[key]];
};
