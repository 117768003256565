export const isArray = (input) => isOfType(input, 'array');
export const isNumber = (input) => isOfType(input, 'number');
export const isObject = (input) => isOfType(input, 'object');
export const isString = (input) => isOfType(input, 'string');
export const isDate = (input) => isOfType(input, 'date');
export const isNull = (input) => isOfType(input, 'null');
export const isUndefined = (input) => isOfType(input, 'undefined');
export const isBoolean = (input) => isOfType(input, 'boolean');

export function isOfType(input, type) {
	return Object.prototype.toString
		.call(input)    // => "[object Type]"
		.split(' ')[1]  // => "Type]"
		.slice(0, -1)   // => "Type"
		.toLowerCase() === type.toLowerCase(); // => "type === type"
}
