import {toDashCase, toCamelCase} from './string-helpers';

/**
 * @module Sidekick.EasyDom
 * @memberOf Sidekick
 */

/**
 * Creates a new {@link EasyDom} element from the Element.
 * @function element
 * @instance
 * @param {Element} el - Element from which to wrap in {@link EasyDom}
 * @return {EasyDom} the new {@link EasyDom} element
 */
export const element = el => new EasyDom(el);

/**
 * Finds an {@link EasyDom} element based on the selector.
 * @function find
 * @instance
 * @param {String} selector - Selector to look for elements from
 * @param {EasyDom} [parent=false] - Parent element to start the search in
 * @return {Array}
 */
export const find = elSelector;

/**
 * Parses an HTML string, creates an Element, then returns it as an {@link EasyDom} element.
 * @function parse
 * @instance
 * @param {Element} el - Element from which to wrap in {@link EasyDom}
 * @return {EasyDom} the new {@link EasyDom} element
 */
export const parse = function (html) {
	const el = document.createElement('span');
	el.innerHTML = html;
	if (el.children.length === 1) { return element(el.children[0]); }
	return element(el);
};

/**
 * Runs a function on a timer (requestAnimationFrame)
 * @function rAF
 * @instance
 * @param {Function} callback - Callback when the timer runs
 */
export const rAF = (
	(window.requestAnimationFrame && window.requestAnimationFrame.bind(window)) ||
	(window.webkitRequestAnimationFrame && window.webkitRequestAnimationFrame.bind(window)) ||
	(window.mozRequestAnimationFrame && window.mozRequestAnimationFrame.bind(window)) ||
	(window.oRequestAnimationFrame && window.oRequestAnimationFrame.bind(window)) ||
	(window.msRequestAnimationFrame && window.msRequestAnimationFrame.bind(window)) ||
	function (callback) { window.setTimeout( callback, 1000 / 60 ); }
);

const domCache = [];

/**
 * EasyDom wrapper around HTML elements.
 * @class
 */
class EasyDom {
	/**
	 * @param {Element} domNode
	 */
	constructor(domNode) {
		// if we have already built an EasyDom object for this domNode, pull it from cache
		if (domNode._easyDomID != null) { return domCache[domNode._easyDomID]; }
		this._ = domNode;
		domNode._easyDomID = domCache.length;
		domCache.push(this);
	}

	/**
	 * @inner
	 * @param {String} selector - Selector for finding an element
	 * @return {EasyDom}
	 */
	find(selector) { return elSelector(selector, this._); }

	/**
	 * @param {String} cls - Classname to add
	 * @return {EasyDom} Reference to the current {@link EasyDom} element.
	 */
	addClass(cls) {
		const prefix = (this._.className.length ? ' ' : '');
		if (!this.hasClass(cls)) { this._.className += `${prefix}${cls}`; }
		return this;
	}

	/**
	 * @param {String} cls - Classname to remove
	 * @return {EasyDom} Reference to the current {@link EasyDom} element.
	 */
	removeClass(cls) {
		this._.className = this._.className
			.split(' ')
			.filter(c => c !== cls && c.length > 0)
			.join(' ');
		return this;
	}

	/**
	 * @param {String} cls - Classname to test if this element has.
	 * @return {Boolean} Whether or not this element has the classname specified.
	 */
	hasClass(cls) { return this._.className.split(' ').includes(cls); }

	/**
	 * @param {String} cls - Classname to toggle
	 * @param {String} [state] - current state
	 * @return {EasyDom} Reference to the current {@link EasyDom} element.
	 */
	toggleClass(cls, state) {
		if (state == null) { state = !this._.className.split(' ').includes(cls); }
		return this[`${state ? 'add' : 'remove'}Class`](cls);
	}

	/**
	 * @param {EasyDom|EasyDom[]} elem - Element to append to this element's children
	 * @return {EasyDom} Reference to the current {@link EasyDom} element.
	 */
	append(elem) {
		if (elem == null) { return; }
		const elCount = elem.length;
		if (elCount == null) {
			this._.appendChild(elem instanceof EasyDom ? elem._ : elem);
		} else {
			// we have an array of elements, recurse over the elements
			for (let i = 0; i < elCount; i++) { this.append(elem[i]); }
		}
		return this;
	}

	/**
	 * @param {String} name - Attribute name to get/set value for
	 * @param {*} [value] - Value to set the attribute to
	 * @return {EasyDom} Reference to the current {@link EasyDom} element.
	 */
	attr(name, value) {
		if (value === undefined) { return this._.getAttribute(name); }
		if (value === null) { this._.removeAttribute(name); return this; }

		this._.setAttribute(toDashCase(name), value);
		return this;
	}

	/**
	 * @param {String} name - Data attribute name to get/set value for
	 * @param {*} [value] - Value to set the attribute to
	 * @return {EasyDom} Reference to the current {@link EasyDom} element.
	 */
	data(name, value) {
		return this.attr(`data-${toDashCase(name)}`, value);
	}

	/**
	 * Empties this element of any children
	 * @return {EasyDom} Reference to the current {@link EasyDom} element.
	 */
	empty() {
		while (this._.firstChild) {
			this._.removeChild(this._.firstChild);
		}
		return this;
	}

	/**
	 * Sets the children to be the content being passed in.
	 * @param {EasyDom|EasyDom[]} content
	 * @return {EasyDom} Reference to the current {@link EasyDom} element.
	 */
	children(content) {
		return this.empty().append(content);
	}

	/**
	 * Returns the {@link EasyDom} parent for this element
	 * @return {EasyDom}
	 */
	parent() {
		return new EasyDom(this._.parentNode);
	}

	/**
	 * Registers an event handler to an event.
	 * @param {String} evt - Event to react to
	 * @param {Function} handler - Function to handle the event
	 * @return {EasyDom} Reference to the current {@link EasyDom} element.
	 */
	on(evt, handler) {
		this._listeners = this._listeners || [];
		this._listeners.push({evt, handler});
		this._.addEventListener(evt, handler);
		return this;
	}

	/**
	 * Deregisters an event handler to an event.
	 * @param {String} evt - Event to react to
	 * @param {Function} handler - Function to handle the event
	 * @return {EasyDom} Reference to the current {@link EasyDom} element.
	 */
	off(evt, handler) {
		var toRemove = [];
		if (evt == null && handler == null) {
			// remove all listeners
			toRemove = this._listeners || [];
		} else if (handler == null) {
			// remove all listeners of this type
			toRemove = this._listeners.filter(h => h.evt === evt);
		} else {
			// remove just one specific listener
			toRemove = [this._listeners.find(h => h.evt === evt && h.handler === handler)].filter(Boolean);
		}
		// if we couldn't find the listener, bail out
		if (toRemove.length === 0) { return this; }
		// remove the dom listeners
		toRemove.forEach(h => this._.removeEventListener(h.evt, h.handler));
		// clean out the listeners log
		this._listeners = this._listeners.filter(l => !toRemove.includes(l));
		return this; // for chaining
	}

	/**
	 * Sets the css property(ies) to the values
	 * @param {Object|String} target
	 * @param {String|Number} [value] - Only used if the target is a String
	 * @return {EasyDom} Reference to the current {@link EasyDom} element.
	 */
	css(target, value) {
		if (target instanceof Array) { return; }
		if (target instanceof Object) {
			const keys = Object.keys(target);
			for (let i=keys.length; i-- ;) {
				const k = keys[i];
				this.css(k, target[k]);
			}
		}
		if (typeof target === 'string') {
			if (value === undefined) {
				return window.getComputedStyle(this._)[target];
			}
			this._.style[toCamelCase(target)] = value;
		}
		return this;
	}

	/**
	 * Checks if this element currently has focus
	 * @returns {Boolean}
	 */
	hasFocus() {
		return window.document.activeElement === this._;
	}
}

function elSelector(selector, parent = false) {
	const context = parent || document;
	const isComplex = /^..*[\.\# ]+.+$/;
	const useQSA = isComplex.test(selector);
	const qsa = 'querySelectorAll';
	var method = {
		'#': 'ById',
		'.': 'sByClassName',
		'@': 'sByName',
		'=': 'sByTagName'
	}[selector[0]];

	method = (method == null || useQSA ? qsa : `getElement${method}`);
	if (method !== qsa) { selector = selector.slice(1); }
	return map(context[method](selector), el => new EasyDom(el));
}

function map(collection, func) {
	return Array.prototype.map.call(collection, func);
}
