import nonProdProductIdMap from './env-prodProductIdMap.json';
// the product usertype map is only used for 1 deprecated attr on the sidekick
import nonProdProductUserTypeMap from './env-productsToUserTypes.json';

export const dev = {
	ssBaseUrl: 'https://supersuit.flqa.net',
	ecSand: 'https://ecsandproxyawsdev.flqa.net',
	announcementsBaseUrl: 'https://msvc-plat-announcements-stage.ss.frontlineeducation.com',
	productUserTypeMap: nonProdProductUserTypeMap,
	productIdMap: nonProdProductIdMap,
	dataExporterBaseUrl: 'https://pde-dev.ss.frontlineeducation.com',
	passportAuthDomain: 'login.dev-k12.ss.frontlineeducation.com',
	passportDomain: 'dev-k12.ss.frontlineeducation.com',
	vendorSSOApiUrl: 'https://vendorsso-dev-api.ss.frontlineeducation.com',
	vendorSSOBaseUrl: 'https://vendorsso-ui-dev.ss.frontlineeducation.com',
	permissionServiceBaseUrl:
		'https://platform-permission-dev.ss.frontlineeducation.com'
};

export const qa = {
	ssBaseUrl: 'https://supersuit.flqa.net',
	ecSand: 'https://ecsandproxyawsqa.flqa.net',
	announcementsBaseUrl: 'https://msvc-plat-announcements-stage.ss.frontlineeducation.com',
	productUserTypeMap: nonProdProductUserTypeMap,
	productIdMap: nonProdProductIdMap,
	dataExporterBaseUrl: 'https://pde-dev.ss.frontlineeducation.com',
	vendorSSOApiUrl: 'https://vendorsso-dev-api.ss.frontlineeducation.com',
	vendorSSOBaseUrl: 'https://vendorsso-ui-dev.ss.frontlineeducation.com',
	rubricDesignerUrl: 'https://app-rubricdesigner.pg-dev.int.frontlineeducation.com',
	permissionServiceBaseUrl:
		'https://platform-permission-stage.ss.frontlineeducation.com'
};

export const stage = {
	ssBaseUrl: 'https://supersuit.flqa.net',
	ecSand: 'https://ecsandproxyawsstage.flqa.net',
	announcementsBaseUrl: 'https://msvc-plat-announcements-stage.ss.frontlineeducation.com',
	productUserTypeMap: nonProdProductUserTypeMap,
	productIdMap: nonProdProductIdMap,
	dataExporterBaseUrl: 'https://exporter.ss.frontlineeducation.com',
	passportAuthDomain: 'login.stage-k12.ss.frontlineeducation.com',
	passportDomain: 'stage-k12.ss.frontlineeducation.com',
	vendorSSOApiUrl: 'https://vendorsso-stage-api.ss.frontlineeducation.com',
	vendorSSOBaseUrl: 'https://vendorsso-ui-stage.ss.frontlineeducation.com',
	rubricDesignerUrl: 'https://app-rubricdesigner.pg-stage.int.frontlineeducation.com',
	permissionServiceBaseUrl:
		'https://platform-permission-stage.ss.frontlineeducation.com'
};

export const prod = {
	ssBaseUrl: 'https://app.frontlineeducation.com',
	ecSand: 'https://api2.frontlineeducation.com',
	announcementsBaseUrl: 'https://msvc-plat-announcements.use1.frontlineeducation.com',
	productUserTypeMap: {
		'EC': 'employee',
		'IepLegacyBeta': 'ss_tri_state_user_beta',
		'504LegacyBeta': 'ss_tri_state_user_beta',
		'RtiLegacyBeta': 'ss_tri_state_user_beta',
		'EsaLegacyBeta': 'ss_tri_state_user_beta',
		'MedicaidLegacyBeta': 'ss_tri_state_user_beta',
		'IepLegacy': 'ss_tri_state_user',
		'504Legacy': 'ss_tri_state_user',
		'RtiLegacy': 'ss_tri_state_user',
		'EsaLegacy': 'ss_tri_state_user',
		'MedicaidLegacy': 'ss_tri_state_user',
		'ta': 'abs_time_user',
		'AbsMgmt': 'abs_time_user',
		'pd': 'pd_user',
		'recruit': 'recruit_user',
		'IepEnrich': 'ss_enrich_user',
		'cc': 'cc_user',
		'platformTools': 'fl-employee',
	},
	productIdMap: {},
	dataExporterBaseUrl: 'https://exporter.frontlineeducation.com',
	passportAuthDomain: 'login.k12.frontlineeducation.com',
	passportDomain: 'k12.frontlineeducation.com',
	vendorSSOApiUrl: 'https://vendorsso-api.frontlineeducation.com',
	vendorSSOBaseUrl: 'https://vendorsso-ui.use1.frontlineeducation.com',
	rubricDesignerUrl: 'https://app-rubricdesigner.pg-prod.frontlineeducation.com'
};

export function productIdFromMap(productId, productIdMap) {
	const pIdLower = productId.toLowerCase();
	return productIdMap[pIdLower] || pIdLower;
}

export function environmentFromIdmGatewayUrl(idmGatewayUrl) {
	const isAws = /\/\/idgatewayaws(.*?)\.flqa\.net/gi;
	const awsMatch = isAws.exec(idmGatewayUrl);
	if (awsMatch !== null) {
		return awsMatch[1];
	} else {
		return 'prod';
	}
}

export function environmentFromPassportDomain(domain) {
	if (prod['passportDomain'] === domain) {
		return 'prod';
	}
	// Added the condition to handle new subdomain url
	if (stage['passportDomain'] === domain || domain.includes(stage['passportDomain'])) {
		return 'stage';
	}
	return 'dev';
}
