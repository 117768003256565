import * as env from '../../util/env';

export default function() {
	const envName = env.environmentFromPassportDomain(window.location.host);
	if (envName === 'stage') {
		const $el = window.document.getElementById('override-passport-footer');
		$el.innerText = `env: stage`;
		$el.style.display = 'block';
	}
};
