import * as dom from '../../util/easy-dom';
import * as events from '../../util/event-bus';

export default function (elem, attrs, ctx, i18n) {
	var orgId, productId;

	events.emit('header.visible', false);
	events.emit('nav.visible', false);

	dom.rAF(() => dom.rAF(() => elem.addClass('sk--fade-in')));

	events.on('_pick-org.org-id-selected', oId => orgId = oId);
	events.on('_pick-app.product-id-selected', function (pId) {
		productId = pId;
		events.emit('_pick-org-app.selected', {orgId, productId});
	});
	events.on('pick-org-app.abort', function () {
		elem.css('display', 'none');
	}, true);
};
