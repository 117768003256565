export const handleVendorNavItemClick = (vendorId, orgId, idm) => {
  if (window.vendorSSO) {
    if (idm && (idm.accessToken || idm.getAccessToken)) {
      window.vendorSSO.store.set('vendorSSO-data', {
        accessToken: idm.accessToken,
        getAccessToken: idm.getAccessToken,
        orgId
      });
      window.vendorSSO.open({
        id: vendorId
      });
    } else {
			// eslint-disable-next-line no-console
      console.error('Sidekick Error: `idm` store does not contain the access token data.');
    }
  } else {
    // eslint-disable-next-line no-console
    console.error('Sidekick Error: `vendorSSO` is not available in `window` object');
  }
};
