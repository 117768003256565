import * as load from './util/asset-loader';
import cycle from './cmpt/index';
import * as store from './util/store';
import * as translations from './util/translations';
import * as domScanner from './util/dom-scanner';
import './util/idm';
import './util/passport';
import { setupFeatureFlagsCC, setupPassportFeatureFlagsCC } from './util/feature-flags-cc';
import { checkViewOthersPermission } from './util/view-permissions';
import * as env from './util/env';

/**
 * Initializes the Sidekick
 * @module Sidekick.Init
 * @memberOf Sidekick
 */

/**
 * Initializes the sidekick
 * @param {Object} cfg - Configuration Object
 * @param {String} cfg.token - Access Token
 * @param {String} cfg.product - Application
 * @param {Number} cfg.flId - Frontline User ID
 * @param {Element} [cfg.appElement=document.body] - Element the sidekick should be attached to
 * @param {Boolean} [cfg.addIcons=true] - Add in the icons
 * @param {Boolean} [cfg.addOpenSans=true] - Add in OpenSans
 * @param {Boolean} [cfg.addSidekickCss=true] - Add Sidekick CSS file to header
 * @param {Boolean} [cfg.scopeSidekickIcons=true] - Scope Sidekick icons to Sidekick to use specific FontAwesome version
 * @param {Boolean} [cfg.autoAppendAppNameInTitle=false] - Scans the TITLE node to ensure the application name is appended
 * @param {Boolean} [cfg.language=en] - Language to set any translations strings
 */
export default function (cfg = {}) {
	// prevent a second init of the sidekick
	if (store.get('sk-config') != null) { return; }

	// Hookup feature flags
	store.onValueOnce('idm-data', (idmData) => {
		setupFeatureFlagsCC(idmData);
		checkViewOthersPermission(idmData);
	});

	// Hookup feature flags
	store.onValueOnce('passport-data', (context) => {
		setupPassportFeatureFlagsCC(context);
	});

	store.onValueOnce('idm', function (cfg) {
		const envName = env.environmentFromIdmGatewayUrl(cfg.idGatewayUrl);
		Promise.all([
			load.script('vendorSSO.js', env[envName].vendorSSOBaseUrl),
			load.stylesheet('vendorSSO.css', () => null, env[envName].vendorSSOBaseUrl)
		]);
	});

	const bodyComponents = [
		{ name: 'header', ctxStore: false },
		{ name: 'unsupported-browser-alert', ctxStore: 'idm' },
		{ name: 'nav', ctxStore: 'nav' },
		{ name: 'pick-org-app', ctxStore: 'pick-org-app-data' },
		{ name: 'session-timeout', ctxStore: 'session-timeout' },
		{ name: 'site-errors', ctxStore: false },
		{ name: 'footer', ctxStore: false }
	];
	const configDefaults = {
		appElement: document.body,			// the html element the sk should surround
		mainElement: null,					// the main content element for the "skip nav" link
		addIcons: true,						// automagically add the icon font
		addOpenSans: true,					// automagically add open sans font-face
		addSidekickCss: true,				// automagically add sidekick.css to the header
		scopeSidekickIcons: false,
		autoAppendAppNameInTitle: false,	// scans the title to ensure app name is appended
		cleanCache: false,                  // cleans out the local and session storage cache
		language: 'en'						// language to set any translations strings
	};

	// setup the app config values
	const config = Object.assign({}, configDefaults, cfg);
	store.set('sk-config', config);
	store.set('fl-internal-product-ids', ['platformtools', 'doc']);

	translations.init(config.language);

	const appEl = config.appElement;
	if (config.addIcons) { load.fontFace('Fl-Icons'); }
	if (config.addOpenSans) { load.fontFace('Open-Sans'); }
	if (config.addSidekickCss) {
		load.stylesheet('sidekick.css', goInit);
	} else if (config.scopeSidekickIcons) {
		load.stylesheet('scoped-sidekick.css', goInit);
	} else {
		goInit();
	}

	if (config.autoAppendAppNameInTitle) { domScanner.title(); }
	load.printStyles();

	function goInit() {
		if (appEl.className.length) { appEl.className += ' '; }
		appEl.className += 'sidekick-wrapped-application';

		for (let i = bodyComponents.length; i--;) {
			const { name, ctxStore } = bodyComponents[i];
			const el = document.createElement('div');
			el.setAttribute(`sk-${name}`, '');
			if (ctxStore) { el.setAttribute('context-store', ctxStore); }
			if (appEl.firstChild == null) {
				appEl.appendChild(el);
			} else {
				appEl.insertBefore(el, appEl.firstChild);
			}
		}
		cycle();
	}
}
